let Environment = {
  MintContract: "0x18B97FeB170eB6983aE79769B051d35e8103DC08",
  TomiTokenContract: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  USDTMainContract: "0x55d398326f99059fF775485246999027B3197955",
  // DoaContract: "0xBc9876EEA1B5a03f68a15Ed0ca75F3AD51B195f9",
  // MintingDaap: "0xDB8e9d97D000c5922B678E4660E44e84375d3099",
   // MintingDaap: "0xDB8e9d97D000c5922B678E4660E44e84375d3099",
  TomiTokenDao: "0xC17531a42053ed670D1D53D4D68C9EF86B629907",
  TomiMainDao: "0xADb99B93151D279f304e8520078B8A62075E5BF1",
  TomiFundsDao: "0x374Be7753e27b33D1A99ef7020093196e0F13246",
  Treasury: "0x2c6eF2306E2B81FACD213e1D66509847e2159d64",
  PioneerNftDao: "0x3f4E7A7a1793753A5b95A38714abB5Ece60c8ef2",
  TomiAuctionDao: "0x51AeF83B3Da8A65C1E7127BCde7E4e72821Fa8C3",
  TomiDesignDao: "0x557439afeAa70Ae3e81A013b4DA029aa9E11841c",
  TomiDevFunds: "0x6F2e843CBF769cbd00aD8Ab26136b16165137B83",
  UStTokenAddress:"0xdAC17F958D2ee523a2206206994597C13D831ec7",
  CreateProposal:"0xDEae2AbB4bc7C0cD52de2677922aE03F9A2F14eb",
  TomiPrice: "0x4c7f63B6105Ff95963fC79dB8111628fa014769b",
  VolumePrice: "0xD6e2593e38d0a9166e60C130ee39Ed825E4DC513"
};

export default Environment;
// 0x07BB1Aa8511a85b29067EBd82A101b3A98A860eA
// 0x40118DBDC838e2f91A9987f898D55519510f887f