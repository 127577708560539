import React, {useEffect } from "react";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Landing from "./components/Landing";
// import useEagerConnect from "./hooks/useEagerConnect"; 
import SubmitPorposal from "./components/submitPorposal/SubmitPorposal"; 
import Navbar from "./components/Navbar/Navbar";
import Executed from "./components/buttonComponent/Executed";
import { GetUsers } from "./redux/action";
import Editprofile from "./components/Editprofile/Editprofile";
import {useDispatch} from 'react-redux'
import { useWeb3React } from "@web3-react/core";
import SimpleProposal from "./components/submitPorposal/SimpleProposal";
import AddFund from "./components/submitPorposal/AddFund";
import AddContraction from "./components/submitPorposal/AddContraction";
import ArtDaoProposal from "./components/submitPorposal/ArtDaoProposal";
import Footernew from "./components/footernew/Footernew";
import Navbartop from "./components/headertop/Navbartop";
import NavbarNew from "./components/NavbarNew/NavbarNew";
import Useraagreement from "./components/Privacypolicy/Useraagreement";
import Privacypolicy from "./components/Privacypolicy/Privacypolicy";
function App() {
  const { account } = useWeb3React();
  const token = JSON.parse(localStorage.getItem('mytoken'))
  // console.log("token",token)
  // useEagerConnect();

  const dispatch=useDispatch();
  useEffect(() => {
    dispatch(GetUsers(account,token?.token));
  }, [token,account]);
  
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        <Switch>
          <Route exact path="/home">
            <Landing />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
          <Route exact path="/porposal">
            <NavbarNew />
            <Navbar />
            <SubmitPorposal />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/simpleproposal">
         <NavbarNew />
            <Navbar />
            <SimpleProposal />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/addfund">
          <NavbarNew />
            <Navbar />
            <AddFund />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/addcontraction">
          <NavbarNew />
            <Navbar />
            <AddContraction />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/artdaoproposal">
          <NavbarNew />
            <Navbar />
            <ArtDaoProposal />
            {/* <Footer /> */}
            <Footernew />
          </Route>
       
          <Route exact path="/proposal/:id">
          <NavbarNew />
            <Navbar />
            <Executed />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/editprofile">
          <NavbarNew />
            <Navbar />
            <Editprofile />
            {/* <Footer /> */}
            <Footernew />
          </Route>
          <Route exact path="/useragreement">
            <Useraagreement />
            <Footernew />
          </Route>
          <Route exact path="/privacy">
            <Privacypolicy />
            <Footernew />
          </Route>
             <Redirect to={`/`}></Redirect>
        </Switch>
      </Router>
    </>
  );
}

export default App;
