import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import { getTomiTokenProposalContract, getPioneerDaoContract, getAllProposal, getAuctionContract, getMainDaoContract, getDevFundContract } from "../../utils/contractHelpers";
import { parseHTML } from "jquery";
import Environment from "../../utils/Environment";
import { getFullDisplayMul } from '../../utils/formatBalance'
const UserProposalInteraction = () => {
    const web3 = useWeb3();
    const contractAddress = Environment.CreateProposal;
    const contract = getAllProposal(contractAddress, web3);
    const UserProposeContrct = useCallback(
        async (description, title, selectedFun, nftIds, tuple, address, account, quorumCategory) => {
            let functionId = ''
            console.log('detailed', description, title, selectedFun, nftIds, tuple, address, account, quorumCategory)
            // let contract = ''
            if (address === Environment.TomiTokenDao) {
                console.log("hereTomiTokenDao");
                // contract = getTomiTokenProposalContract(address, web3);
            } else if (address === Environment.PioneerNftDao) {
                console.log("here1", address);
                // contract = getPioneerDaoContract(address, web3);
            } else if (address === Environment.TomiAuctionDao) {
                console.log("here2", address);
                // contract = getAuctionContract(address, web3);
            } else if (address === Environment.TomiMainDao) {
                console.log("here2", address);
                // contract = getMainDaoContract(address, web3);
            }
            else if (address === Environment.TomiDevFunds) {
                console.log("here2", address);
                // contract = getDevFundContract(address, web3);
            }
            //   let a = [calldatas]
            //   let a= parseInt(calldatas) 
            //   calldatas=[parseInt(calldatas)]
            if (selectedFun === 'updateEmissions') {
                let target = []
                let values = []
                let signatures = []
                let calldatas = []
                let dumArr = []
                let final1 = []
                target.push(Environment.TomiTokenContract)
                values.push('0')
                signatures.push('updateEmissions((uint256,uint256,uint256,uint256,uint256,bool))')
                // calldatas.push(tuple)
                let final = web3.eth.abi.encodeParameters(['uint256', 'uint256', 'uint256', 'uint256', 'uint256', 'bool'], ['0', '0', '0', ...tuple])
                calldatas.push(final)
                // console.log('detailed' , selectedFun,functionId,tuple,dumArr)
                console.log("sdfsfdsfdsf", target, values, nftIds, signatures, calldatas, title, description)
                // let convertedvalue =[web3.utils.toWei(values.toString(), "ether")]
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods.propose(nftIds, target, values, signatures, calldatas, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, calldatas, title, description)
                        .send({
                            from: account,
                            // value: web3.utils.toWei(fee.toString(), "ether"),
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if (selectedFun === 'updateMarketingWallet' || selectedFun === 'updateCoreTeamWallet' || selectedFun === 'updateFutureTeamWallet') {
                let target = []
                let values = []
                let signatures = []
                let calldatas = []
                let dumArr = []
                let final1 = []
                values.push('0')
                target.push(Environment.TomiTokenContract)
                signatures.push(`${selectedFun}(address)`)
                calldatas.push(tuple)
                let final = web3.eth.abi.encodeParameters(['address'], calldatas)
                final1.push(final)
                // console.log('detailed' , selectedFun,functionId,tuple,dumArr)
                console.log("sdfsfdsfdsf", nftIds, target, values, signatures, final1, title, description)
                //   console.log("sdfsfdsfdsf",targets, values, signatures,calldatas,address,contract1, description, title)
                // let convertedvalue =[web3.utils.toWei(values.toString(), "ether")]
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description)
                        .send({
                            from: account,
                            // value: web3.utils.toWei(fee.toString(), "ether"),
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if(selectedFun === 'fundDao' ){
                let target = []
                let values = []
                let signatures = []
                let calldatas = []
                let dumArr = []
                let final1 = []
                // let final1 = web3.eth.abi.encodeParameters([''],'')
                target.push(Environment.TomiTokenContract)
                signatures.push('fundDao()')
                values.push('0')
                final1.push('0x')
                // console.log('detailed' , selectedFun,functionId,tuple,dumArr)
                console.log("sdfsfdsfdsf", nftIds, target, values, signatures, final1, title, description)
                //   console.log("sdfsfdsfdsf",targets, values, signatures,calldatas,address,contract1, description, title)
                // let convertedvalue =[web3.utils.toWei(values.toString(), "ether")]
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description)
                        .send({
                            from: account,
                            // value: web3.utils.toWei(fee.toString(), "ether"),
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            }
             else if (selectedFun === 'proposeBlockNFTWallet') {
                let dumArr = []
                // dumArr.push(tokenid)
                // console.log('detailed' , selectedFun,functionId,tuple,dumArr)
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .propose(description, nftIds, title, functionId, tuple).estimateGas({ from: account})
                    const details = await contract.methods
                        .propose(description, nftIds, title, functionId, tuple)
                        .send({
                            from: account,
                            gas,
                            gasPrice: gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if (selectedFun === 'proposeSendNFT') {
                let target = ['0x4fd80B4e2005AB5ca1ED8d708a6Da5959A989565']
                let values = []
                let signatures = []
                let calldatas = []
                let final1 = []
                values.push('0')
                // target.push(address)
                signatures.push('transferFrom(address,address,uint256)')
                calldatas.push(Environment.Treasury, tuple.recipient, tuple?.elem?.token_id)
                console.log("pioneeer", calldatas)
                let final = web3.eth.abi.encodeParameters(['address', 'address', 'uint256'], calldatas)
                final1.push(final)
                console.log("sdfsfdsfdsf", target, values, nftIds, signatures, calldatas, title, description, final1)
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description)
                        .send({
                            from: account,
                            // value: web3.utils.toWei(fee.toString(), "ether"),
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if (selectedFun === 'changeMinimumBidIncreasePercentage' || selectedFun === 'changeReservePrice(Starting Bid)' || selectedFun === 'changeTimeBuffer') {
                let dumArr = []
                // dumArr.push(tokenid)
                let convertedvalue = web3.utils.toWei(tuple.toString(), "ether")
                console.log('detailed', description, title, selectedFun, nftIds, functionId, convertedvalue, address, account)
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .proposeChangeAuction(description, nftIds, title, functionId, tuple).estimateGas({ from: account })
                    const details = await contract.methods
                        .proposeChangeAuction(description, nftIds, title, functionId, tuple)
                        .send({
                            from: account,
                            // value: '0',
                            gas,
                            gasPrice: gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if (selectedFun === '_setProposalMinAmountNFTs' || selectedFun === '_setVoteMinAmountNFTs' || selectedFun === '_setProposalFee') {
                let target = []
                let values = []
                let signatures = []
                let calldatas = []
                let dumArr = []
                let final1 = []
                let dumArr1 = []
                console.log("hereeeee");
                target.push(Environment.CreateProposal)
                signatures.push(`${selectedFun}(uint256)`)
                values.push('0')
                if (selectedFun === '_setProposalFee') {
                    // let ac = parseInt(tuple)
                    //   let convertedvalue =getFullDisplayMul(tuple)
                    let convertedvalue = web3.utils.toWei(tuple, "ether")
                    let ab = convertedvalue + '00000000'
                    let final = web3.eth.abi.encodeParameters(['uint256'], [ab])
                    calldatas.push(final)
                    console.log('detailedDao')
                    // dumArr1.push(ab)
                } else if (selectedFun === '_setProposalMinAmountNFTs' || selectedFun === '_setVoteMinAmountNFTs') {
                    console.log('detailedDao::::')
                    let final = web3.eth.abi.encodeParameters(['uint256'], [tuple])
                    calldatas.push(final)
                    // dumArr1.push(tuple)
                }


                console.log("sdfsfdsfdsf", target, values, nftIds, signatures, calldatas, title, description)
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .propose(nftIds, target, values, signatures, calldatas, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, calldatas, title, description)
                        .send({
                            from: account,
                            // value: web3.utils.toWei(fee.toString(), "ether"),
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if (selectedFun === '_setQuorumPercentage') {
                let target = []
                let values = []
                let signatures = []
                let calldatas = []
                let final = ''
                let dumArr1 = []
                console.log("hereeeeeQ");
                target.push(Environment.CreateProposal)
                signatures.push('_setQuorumPercentage(uint8,string,address,uint256)')
                if (quorumCategory === 'Funds') {
                    if (tuple.fundAmount === '<100000') {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['1', "transfer1", '0x0000000000000000000000000000000000000000', tuple.value])
                    } else if (tuple.fundAmount === '100000-500000') {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['1', "transfer2", '0x0000000000000000000000000000000000000000', tuple.value])
                    } else if (tuple.fundAmount === '500000<') {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['1', "transfer3", '0x0000000000000000000000000000000000000000', tuple.value])
                    } else if (tuple.Quorum === '721') {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['1', "transferFrom(address,address,uint256)", '0x0000000000000000000000000000000000000000', tuple.value])
                    } else if (tuple.Quorum === '1155') {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['1', "safeTransferFrom(address,address,uint256,uint256,bytes)", '0x0000000000000000000000000000000000000000', tuple.value])
                    }
                } else if (quorumCategory === 'Tomi Token') {
                    if (tuple.Quorum === "Emission") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "updateEmissions((uint256,uint256,uint256,uint256,uint256,bool))", '0xbf3232936c38413eAFA4DB0C1Bd0303dEF10989e', tuple.value])
                    } else if (tuple.Quorum === "CoreTeam") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "updateCoreTeamWallet(address)", '0xbf3232936c38413eAFA4DB0C1Bd0303dEF10989e', tuple.value])
                    } else if (tuple.Quorum === "FutureTeam") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "updateFutureTeamWallet(address)", '0xbf3232936c38413eAFA4DB0C1Bd0303dEF10989e', tuple.value])
                    } else if (tuple.Quorum === "Marketing") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "updateMarketingWallet(address)", '0xbf3232936c38413eAFA4DB0C1Bd0303dEF10989e', tuple.value])
                    }
                } else if (quorumCategory === 'Pioneer NFT') {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "transferFrom(address,address,uint256)", '0x4fd80B4e2005AB5ca1ED8d708a6Da5959A989565', tuple.value])
                } else if (quorumCategory === 'DAO') {
                    if (tuple.Quorum === "MinAmtNFTForProposal") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "_setProposalMinAmountNFTs(uint256)", '0x1D761df669d37f79D599afD56F2Ac9A256f4d5B7', tuple.value])
                    } else if (tuple.Quorum === "MinAmtNFTForVote") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "_setVoteMinAmountNFTs(uint256)", '0x1D761df669d37f79D599afD56F2Ac9A256f4d5B7', tuple.value])
                    } else if (tuple.Quorum === "proposalFee") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "_setProposalFee(uint256)", '0x1D761df669d37f79D599afD56F2Ac9A256f4d5B7', tuple.value])
                    } else if (tuple.Quorum === "QuorumPercentage") {
                        final = web3.eth.abi.encodeParameters(['uint8', 'string', 'address', 'uint256'], ['0', "_setQuorumPercentage(uint8,string,address,uint256)", '0x1D761df669d37f79D599afD56F2Ac9A256f4d5B7', tuple.value])
                    }
                }
                values.push('0')
                console.log("finalll",final)
                // let final = web3.eth.abi.encodeParameters(['uint8','string','address','uint256'], [tuple])
                calldatas.push(final)
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods
                        .propose(nftIds, target, values, signatures, calldatas, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, calldatas, title, description)
                        .send({
                            from: account,
                            // value: web3.utils.toWei(fee.toString(), "ether"),
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            } else if (selectedFun === 'SendToken') {
                console.log("herrrererererererr12")
                let target = []
                let values = []
                let signatures = []
                let calldatas = []
                let final1=[]
                if (tuple?.currencySend === 'Tomi Token') {
                    target.push(Environment.TomiTokenContract)
                    signatures.push('transfer(address,uint256)')
                    let a = tuple?.amount.toString()
                    let convertedvalue = web3.utils.toWei(a, "ether")
                    calldatas.push(tuple.recipient, convertedvalue)
                    values.push('0')
                    let final = web3.eth.abi.encodeParameters(['address', 'uint256'], calldatas)
                    final1.push(final)

                } else if (tuple?.currencySend === 'ETH') {
                    target.push(tuple?.recipient.toLowerCase())
                    let a = tuple?.amount.toString()
                    let convertedvalue = web3.utils.toWei(a, "ether")
                    signatures.push("")
                    calldatas.push('0x')
                    values.push(convertedvalue)
                    final1.push('0x')
                } else if (tuple.currencySend === 'USDT') {
                    target.push(Environment.UStTokenAddress)
                    signatures.push('transfer(address,uint256)')
                    let a = tuple?.amount.toString()
                    let convertedvalue = web3.utils.toWei(a, "Mwei")
                    calldatas.push(tuple.recipient, convertedvalue)
                    values.push('0')
                    let final = web3.eth.abi.encodeParameters(['address', 'uint256'], calldatas)
                    final1.push(final)
                }
                console.log('detailed funds', nftIds, target, values, signatures, final1,title, description)
                try {
                    let gasPrice = await web3.eth.getGasPrice();
                    const gas = await contract.methods.propose(nftIds, target, values, signatures, final1, title, description).estimateGas({ from: account })
                    const details = await contract.methods
                        .propose(nftIds, target, values, signatures, final1, title, description)
                        .send({
                            from: account,
                            gas,
                            gasPrice:gasPrice
                        })
                    // console.log("resssssss",gas)
                    return details;
                } catch (error) {
                    console.log("catchhhh", error)
                    throw (error)
                }
            }

        },
        [web3,]
    );
    return { UserProposeContrct: UserProposeContrct };
};
export default UserProposalInteraction;