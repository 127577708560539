import React, { useRef, useState, useEffect } from 'react'
import "./submitporposal.scss"
import axios from 'axios';
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";
import UserProposal from '../../hooks/dataSenders/propose';
import UserProposalInteraction from '../../hooks/dataSenders/userpropseContrct'
import TextEditor from "./TextEditor";
import AmountApprove from '../../hooks/dataSenders/approve';
import Signature from "../../hooks/dataSenders/userSign";
import { useBalance, useBalanceUSDT, CheckAllowance } from '../../hooks/useBalances';
import Loader from "../../hooks/loader";
import { useHistory } from 'react-router-dom';
import DoaAbi from '../../utils/DoaAbi.json'
import MintDappAbi from '../../utils/pioneerNftDao.json'
import TomiTokenAbi from '../../utils/TomiTokenAbi.json'
import { toast } from "react-toastify";


function SubmitPorposal() {
  useEffect(() => {
    window.scrollTo(0, 0)
}, [])
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const history = useHistory()
  const [ethusd, setEthUsd] = useState()
  const [userBalance, setUserBalance] = useState()
  const [newArr, setNewArr] = useState([]);
  const [abiFun, setAbiFun] = useState([]);
  const [contractAbi, setContractAbi] = useState([]);
  const [filterdata, setFilterdata] = useState([])
  const [mainLoader, setMainLoader] = useState(false);
  const [allowance, setAllowance] = useState()
  const { UserPropose } = UserProposal()
  const { UserProposeContrct } = UserProposalInteraction()
  const { UserApprove } = AmountApprove()
  const { userSign } = Signature();
  const { CheckUserNft } = useBalance();
  const { CheckBalanceUsdt } = useBalanceUSDT();
  const { CheckAllow } = CheckAllowance();
  // console.log("usd balance", userBalance)
  const [Clickdrop1, setClickDrop1] = useState("");
  const [contactAddress, setContactAddress] = useState()
  const [inputList, setInputList] = useState([{
    recipient: '',
    amount: '',
  }])
  const [inputs, setInputs] = useState({
    title: '',
    description: '',
    sendFunds: false,
    sendTransaction: false,
    length:0
  })
  let [inputContract, setInputsContract] = useState([])

  const setEditor = (data) => {
    setInputs({ ...inputs, description: data, length: data.split(' ').length });
    // console.log("we get here is ", data)
  };

  // console.log("e::::::::::::: ", inputs)

  // console.log("e::::::::::;;kllll;::: ", abiFun)


  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }

  //contract interaction input
  const handleChange = (e, index) => {
    let temp = inputContract
    temp[index] = e.target.value
    // let value = e.target.value
    setInputsContract(temp)
    // console.log("eeee", temp)
    // setInputsContract([(inputContract => ({...inputContract, parseInt([value]:value}))]);
  }

  //for abi function
  const newdumm = () => {
    // console.log("herereere")
    if (contactAddress === '0xDB8e9d97D000c5922B678E4660E44e84375d3099') {
      // console.log("dapp")
      let dumArr = []
      let dumArr2 = []
      for (let i = 0; i < MintDappAbi.length; i++) {
        // console.log("jereree", MintDappAbi[i].type)
        if (MintDappAbi[i].type === "function") {
          if (MintDappAbi[i].stateMutability === "nonpayable") {
            dumArr.push(MintDappAbi[i])
            dumArr2.push(MintDappAbi[i].name)
          }
        }
        setNewArr(dumArr)
        setAbiFun(dumArr2)
      }
    } else if (contactAddress === '0xC8cDEEB67d553466861E53a0f5A009bd756Ad140') {
      // console.log("mint")
      let dumArr = []
      let dumArr2 = []
      for (let i = 0; i < TomiTokenAbi.length; i++) {
        // console.log("jereree", TomiTokenAbi[i].type)
        if (TomiTokenAbi[i].type === "function") {
          if (TomiTokenAbi[i].stateMutability === "nonpayable") {
            dumArr.push(TomiTokenAbi[i])
            dumArr2.push(TomiTokenAbi[i].name)
          }
        }
        setNewArr(dumArr)
        setAbiFun(dumArr2)
      }

    }
  }

  const filterData = (e) => {
    setClickDrop1(e)
    const result = newArr.filter(ab => e === ab.name)
    setFilterdata(result[0].inputs)
  }

  const gata = async () => {
    let res1 = await CheckUserNft(account);
    let res = await CheckBalanceUsdt(account);
    let allowed = await CheckAllow(account);
    setAllowance(allowed)
    setEthUsd(res);
    // let res = await CheckAvailableApprove(account);
    setUserBalance(res1)
  }

  const getContractAddress = () => {
    let a = ''
    if (contactAddress === '0xDB8e9d97D000c5922B678E4660E44e84375d3099') {
      a = JSON.stringify(MintDappAbi)
      // console.log("herer1")
      setContractAbi(a)
    } else if (contactAddress === '0xC8cDEEB67d553466861E53a0f5A009bd756Ad140') {
      // console.log("herer2")
      a = JSON.stringify(TomiTokenAbi)
      setContractAbi(a)
    }
  }

  // Approve Amount fee
  const Approve = async () => {
    try {
      setMainLoader(true)
      let res = await UserApprove(account);
      if (res) {
        setMainLoader(false)
        window.$("#exampleModal11").modal("show");
        let allowed = await CheckAllow(account);
        setAllowance(allowed)
      } else {
        setMainLoader(false)
        window.$("#exampleModal11").modal("show");
      }
      // console.log("ressssss", res)
    } catch (err) {
      setMainLoader(false)
      console.log("eerrrr", err)

    }

  }

  useEffect(() => {
    if (DoaAbi.length > 0 || MintDappAbi.length > 0 || TomiTokenAbi.length > 0) {
      newdumm()
      getContractAddress()
    }
  }, [contactAddress, account, DoaAbi, MintDappAbi, TomiTokenAbi])


  useEffect(() => {
    gata()
  }, [account, allowance])


  //modal sucess failure
  const opeenModal = (e) => {
    if (ethusd > 100) {
      window.$("#exampleModal11").modal("show");
    } else {
      window.$("#exampleModal").modal("show");
    }
  }

  // Modal opening function
  const successs = () => {
    window.$("#exampleModal1").modal("hide");
    history.push('/home')

  }
  const successs1 = () => {
    window.$("#exampleModal").modal("hide");

  }

  //submit proposal  
  const submitProposal = async () => {
    // setMainLoader(true)
    if (inputs.sendFunds) {
      let target = []
      let values = []
      let signatures = ['']
      let calldatas = [0x00]
      for (let i = 0; i < inputList.length; i++) {
        let convertedvalue = web3.utils.toWei(inputList[i].amount, "ether")
        // let a=(inputList[i].amount * 10**18)
        target.push(inputList[i].recipient)
        values.push(convertedvalue)
        // console.log("jereree", values)
      }
      if (account) {
        setMainLoader(true);
        try {
          const res = await UserPropose(
            target,
            values,
            signatures,
            calldatas,
            inputs.description.toString(),
            inputs.title,
            account
          );
          // console.log("proposal res", res)
          setMainLoader(false)
          window.$("#exampleModal11").modal("hide");
          window.$("#exampleModal1").modal("show");

          // setMainLoader(false);
          // await toast.success("Proposal Cancelled Successfully");
        } catch (error) {
          setMainLoader(false)
          window.$("#exampleModal11").modal("hide");
          toast.error(error.message)
          console.log("eerrr", error)
          // await setError(error.message)
          // setMainLoader(false);
        }
      } else {
        setMainLoader(false)
        toast.error("Connect Your Wallet");
      }
      // console.log("jereree", values)
      // console.log("values", target)
    } else if (inputs.sendTransaction) {
      if (account) {
        // let a = JSON.stringify(DoaAbi)
        let contractaddresses = contactAddress
        let target = ['0x3FA586401e7672f22847cEE52D9299dEF6B487fb']
        let values = [0]
        let signatures = []
        try {
          setMainLoader(true);
          const res1 = await userSign();
          signatures.push(res1.toString())
          const res = await UserProposeContrct(
            target,
            values,
            signatures,
            inputs.description.toString(),
            inputs.title,
            contractaddresses,
            Clickdrop1,
            inputContract,
            account
          );
          // console.log("proposal res", res)
          setMainLoader(false)
          window.$("#exampleModal11").modal("hide");
          window.$("#exampleModal1").modal("show");

          // setMainLoader(false);
          // await toast.success("Proposal Cancelled Successfully");
        } catch (error) {
          setMainLoader(false)
          window.$("#exampleModal11").modal("hide");
          toast.error(error.message)
          console.log("eerrr", error)
          // await setError(error.message)
          // setMainLoader(false);
        }
      } else {
        setMainLoader(false)
        toast.error("Connect Your Wallet");
      }
    } else {
      // console.log("hererereere")
      let target = []
      let values = []
      let signatures = ['']
      let calldatas = [0x00]
      if (account) {
        setMainLoader(true);
        try {
          const res = await UserPropose(
            target,
            values,
            signatures,
            calldatas,
            inputs.description.toString(),
            inputs.title,
            account
          );
          // console.log("proposal res", res)
          setMainLoader(false)
          window.$("#exampleModal11").modal("hide");
          window.$("#exampleModal1").modal("show");

          // setMainLoader(false);
          // await toast.success("Proposal Cancelled Successfully");
        } catch (error) {
          setMainLoader(false)
          window.$("#exampleModal11").modal("hide");
          toast.error(error.message)
          console.log("eerrr", error)
          // await setError(error.message)
          // setMainLoader(false);
        }
      } else {
        setMainLoader(false)
        toast.error("Connect Your Wallet");
      }

    }
    // let target = [inputList[0].recipient]
    // let values = [inputList[0].amount ]
    // console.log("values",values)
    // let signatures = ['']
    // let calldatas = [0x00]

  }


  //funds send flow
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList,];
    list[index][name] = value;
    setInputList(list);
  };

  const handleRemoveClick = index => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  const handleAddClick = () => {
    setInputList([...inputList, {
      recipient: '',
      amount: '',
    }]);
  };



  return (
    <>
      {mainLoader && <Loader />}
      <section className='main-submit ptb'>
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              <div className="row">
                <div className="col-lg-6 p-0">


                  {/* <div className="d-flex topTicket">
                    <p>Treasury</p>
                    <p>28,307</p>
                  </div> */}
                  <div className='main-heading'>
                    <h2>Submit Proposal</h2>
                    <p>Title</p>
                    <input type="text" name="title" placeholder='Enter title' onChange={handleChange1} />
                  </div>
                  <div className='textareadescription'>
                    <div className="form-group">
                      <label>Description</label>
                      <TextEditor onEditorChange={setEditor} />
                    </div>
                    {inputs.length >=50 ? '': <p className='error'>Minimum 50 Characters required </p>}
                  </div>
                  {/* <div className='enddate'>
                    <p>End Date</p>
                    <div className='imagess'>
                      <input type="date" name="endDate" placeholder='Select end date' onChange={handleChange1} />
                    </div>

                  </div> */}
                  <div className='main-inputs'>
                    <div className='upperhead topmt'>
                      <h3 className='head'>Send Funds</h3>
                      {!inputs.sendTransaction ?
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customCheck1" onChange={(e) => setInputs(inputs => ({ ...inputs, sendFunds: !inputs.sendFunds }))} />
                          <label class="custom-control-label" for="customCheck1"></label>
                        </div>
                        : ''}
                    </div>
                    {inputs.sendFunds ?
                      <div>
                        <ul>
                          {/* <div>
                         <div className='inputmain'>
                           <p>Recipient</p>
                           <input type="text" placeholder='xxxxxxxx' />
                         </div>
                         <div className='inputmain'>
                           <p>Amount</p>
                           <input type="text" placeholder='xxxxxxxx' />
                         </div>
                       </div> */}
                          {/* {inputList?.map((item, index) => (
                           
                          ))} */}
                          {inputList?.map((x, i) => {
                            return (
                              <>
                                <div>
                                  <div className='inputmain'>
                                    <p>Recipient</p>
                                    <input type="text" name='recipient' placeholder='Enter Recipient' value={x.recipient} onChange={e => handleInputChange(e, i)} />
                                  </div>
                                  <div className='inputmain'>
                                    <p>Amount</p>
                                    <input type="text" name='amount' placeholder='Enter Amount' value={x.amount} onChange={e => handleInputChange(e, i)} />
                                  </div>
                                </div>
                                <div className="btnnnss ">
                                  {inputList.length !== 1 && <button
                                    className='arbtn'
                                    onClick={() => handleRemoveClick(i)}>Remove</button>}<br></br>
                                  {inputList.length - 1 === i && <button className='arbtn' onClick={handleAddClick}> Add More</button>}
                                </div>
                              </>
                            );
                          })}
                        </ul>
                      </div>
                      : ''}
                  </div>

                  <div className='main-inputs'>
                    <div className='upperhead  topmt'>
                      <h3 className='head'>Contract Interaction</h3>
                      <div class="custom-control custom-checkbox">
                        {!inputs.sendFunds ?
                          <>
                            <input type="checkbox" class="custom-control-input" id="customCheck2" onChange={(e) => setInputs(inputs => ({ ...inputs, sendTransaction: !inputs.sendTransaction }))} />
                            <label class="custom-control-label" for="customCheck2"></label>
                          </>
                          : ''}
                      </div>
                    </div>
                    {inputs?.sendTransaction ?
                      <div>
                        <div className='inputmain'>
                          <div class="dropdown buttn">
                            <button class="btn dropbtn" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              {contactAddress ? <span className='text-dark'>{contactAddress}</span> : <span>Contract Addresss</span>}
                              <img src='\arrow-down.svg' className='imgbtn' />
                            </button>
                            {/* {inputs.contractAddress ? */}
                            <div class="dropdown-menu dropmain1" aria-labelledby="dropdownMenuButton1">
                              <a class="dropdown-item item1" onClick={() => { setContactAddress('0xC8cDEEB67d553466861E53a0f5A009bd756Ad140') }}>Tomi Token</a>
                              <a class="dropdown-item item1" onClick={() => { setContactAddress('') }}>TDNS</a>
                              <a class="dropdown-item item1" onClick={() => { setContactAddress('0xDB8e9d97D000c5922B678E4660E44e84375d3099') }}>Minting Daap</a>
                              <a class="dropdown-item item1" onClick={() => { setContactAddress('') }}>Tomi Web</a>

                            </div>
                            {/* : ''} */}
                          </div>
                        </div>
                        <div className='inputmain'>
                          <p>ABI</p>
                          <div className='unlimited'>
                            <p>{contactAddress ? contractAbi : ''}</p>
                          </div>
                          <div class="dropdown buttn">
                            <button class="btn dropbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">

                              {Clickdrop1 ? <span className='text-dark'>{Clickdrop1}</span> : <span>Select Function</span>}
                              <img src='\arrow-down.svg' className='imgbtn' />
                            </button>
                            {contactAddress ?
                              <div class="dropdown-menu dropmain" aria-labelledby="dropdownMenuButton">
                                {abiFun.map((elem) => {
                                  return (
                                    <a class="dropdown-item item1" onClick={() => { filterData(elem) }}>{elem}</a>
                                  )
                                })}
                              </div>
                              : ''}
                          </div>
                        </div>
                        <div className='inputmain'>
                          {filterdata?.map((elem, index) => {
                            return (
                              <>
                                <p>{elem.name}</p>
                                {/* <textarea className='hinput' name='{elem.name}' type="text" placeholder='xxxxxxxxxxx' /> */}
                                <input type="text" name='{elem.name}' key={index} placeholder={`Enter ${elem.name}`} onChange={(e) => handleChange(e, index)} />
                              </>
                            )
                          })}

                        </div>
                      </div>
                      : ''}
                  </div>
                  <div className='submitbutton'>
                    {/* {{if(inputs.description && inputs.title){}else if(){}else{}}} */}
                    {inputs.description && inputs.title  ?
                     userBalance >= 2 ?
                      <button className='aaa' onClick={opeenModal}>Submit Proposal</button>
                      :<p className='error'>You dont have 2 Pioneerss</p>
                      :<p className='error'>Fields Cannot be Empty</p>
                        }
                    {/* <button className='aaa' onClick={opeenModal}>Submit Proposal</button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </section>

      {/* Insufficient Modal */}
      <div className='confirmmodal'>
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">

              <div class="modal-body">
                <div className='main-heading'>
                  <h3>Confirm Transacton</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src='\landing\entypo_cross.png' className='img-fluid crossimg' />
                  </button>


                </div>
                <div className='balance'>
                  <h6>Submit proposal fee $100</h6>
                  <p>Balance USDT: {ethusd}</p>
                  <p>Insuficient balance</p>
                </div>
                <div className='confirmbtn'>
                  <button className='bg-secondary' onClick={successs1}>Confirm</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* Transaction Success Modal===============> */}
      <div className='confirmmodal'>
        <div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">

              <div class="modal-body">
                <div className='main-heading'>
                  <h3>Confirm Transacton</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src='\landing\entypo_cross.png' className='img-fluid crossimg' />
                  </button>


                </div>
                <div className='success'>
                  <img src='\landing\successfully-approve copy 1.svg' className='img-fluid' />
                  <p>Transacton Successful!</p>
                </div>
                <div className='confirmbtn'>
                  <button onClick={successs}>Confirm</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      {/* //sumitporposal modal/// */}
      <div className='confirmmodal'>
        <div class="modal fade" id="exampleModal11" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog" role="document">
            <div class="modal-content">

              <div class="modal-body">
                <div className='main-heading'>
                  <h3>Confirm Transacton</h3>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <img src='\landing\entypo_cross.png' className='img-fluid crossimg' />
                  </button>


                </div>
                <div className='Submitproposal'>
                  <h6>Submit proposal fee $100!</h6>
                  <h6>Worth of Tomi Token</h6>
                  {/* <h3>Balance USDT: {ethusd?.value}</h3> */}
                </div>
                <div className='confirmbtn'>
                  {allowance < 100 ? <button onClick={Approve}>Approve</button> : <button onClick={submitProposal}>Confirm</button>}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>


      
    </>

  )
}

export default SubmitPorposal