import React, { useEffect, useState } from "react";
import "./privacypolicy.scss";
import NavbarNew from "../NavbarNew/NavbarNew";
import Navbar from "../Navbar/Navbar";

const Useraagreement = () => {
  const [objectPositionLeft, setObjectPositionLeft] = useState(0);

  useEffect(() => {
    const windowWidth =
      window.innerWidth - document.getElementById("object").offsetWidth;
    const documentHeight =
      document.documentElement.scrollHeight - window.innerHeight;

    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const objectPositionLeft =
        -10 * scrollPosition * (windowWidth / documentHeight);
      setObjectPositionLeft(objectPositionLeft);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
    {/* <NavbarNew />
    <Navbar /> */}
      <section className="mainprivacypolicy">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto ">
              <div className="privacypolicy">
                <div className="upper-head">
                  <h1
                    id="object"
                    style={{
                      position: "relative",
                      left: objectPositionLeft + "px",
                    }}
                  >
                    tomi User Agreement
                  </h1>
                </div>
                <div className="topprivacypolicy">
                  <h6 className="privacypolicytophead">
                    Last Change: January 9, 2023
                  </h6>
                  <p className="privacypolicytoppara marginbottomzero">
                    This Tomi User Agreement (the “Agreement”), entered into
                    between yourself (“you” or the “User”) and tomi (the
                    “Company”, “we” or “us”), together with any documents they
                    expressly incorporate by reference, govern your access to
                    and use of the Services (as defined below), including any
                    content, functionality, and services offered on or through
                    www.tomi.com (the “Site”).By using the Site or the services
                    offered by the Company (whether through the Site or
                    otherwise) and/or by creating an account in the Site, you
                    agree that you have read, understood, and accept all of the
                    terms and conditions contained in this Agreement, as well as
                    our Privacy Policy and Cookie Policy.
                  </p>
                </div>
                <h5 className="privacypolicymainhead">1. ELIGIBILITY.</h5>
                <p className="privacypolicymainpara marginbot">
                  We supply, through our Site and/or our designated application,
                  Digital Wallet (both, as defined below), decentralized domain
                  naming system, browser and additional services (collectively,
                  our “Services”) and products (collectively, our “Products”).
                  The Company shall have a full discretion whether to provide or
                  to deny, suspend or withhold access to Services to any
                  particular user or group of users at any time and from time to
                  time.
                </p>
                <p className="privacypolicymainpara marginbot">
                  By using the Services you confirm and acknowledge that you
                  understand and accept the terms and conditions of this
                  Agreement and you agree to be bound by them. We may amend,
                  update or change this Agreement from time to time and without
                  prior notice at our discretion, and each such amendment shall
                  become automatically valid and enforceable at the time when
                  first displayed by us in the Site. Therefore, please check
                  this Agreement (which can be found on the Company's website)
                  regularly for changes.
                </p>
                <p className="privacypolicymainpara marginbot">
                  Please avoid using the Site and leave the Site immediately if
                  you are not 18 years old or over, or if there is any legal
                  restriction on using the Site or receiving Services applicable
                  to yourself.
                </p>
                <p className="privacypolicymainpara marginbot">
                  It is forbidden for users to visit the Site or use the Service
                  through anonymous proxies and other services or technologies
                  that hide the real internet connection of the user.
                </p>
                <p className="privacypolicymainpara marginbot">
                  This Agreement shall be valid indefinitely.
                </p>
                <p className="privacypolicymainpara marginbot">
                  If case of a contradiction between this Agreement and the
                  terms, instructions, guidance and similar information found on
                  the Site, the terms of this Agreement shall prevail.
                </p>
                <p className="privacypolicymainpara">
                  THESE TERMS CONSTITUTE A LEGALLY BINDING AND VALID AGREEMENT
                  AND SET FORTH THE LEGALLY BINDING TERMS AND CONDITIONS THAT
                  GOVERN YOUR USE OF THE SITE OR THE SERVICES. BY ACCESSING OR
                  USING THE SITE OR THE SERVICES AND/OR BY USING ANY OF THE
                  SERVICES CONTAINED THEREIN, YOU ARE ACCEPTING THESE TERMS (ON
                  BEHALF OF YOURSELF OR THE ENTITY THAT YOU REPRESENT), AND YOU
                  REPRESENT AND WARRANT THAT YOU HAVE THE RIGHT, AUTHORITY, AND
                  CAPACITY TO ENTER INTO THESE TERMS (ON BEHALF OF YOURSELF OR
                  THE ENTITY THAT YOU REPRESENT). IF YOU DO NOT AGREE WITH ANY
                  OR ALL OF THE PROVISIONS OF THESE TERMS, YOU ARE PROHIBITED
                  FROM ACCESSING, USING, OR TRANSACTING ON THE SITE OR THE
                  SERVICES AND YOU ARE REQUESTED TO LEAVE THE SITE IMMEDIATELY.
                  YOU FURTHER REPRESENT AND WARRANT THAT YOU ARE OTHERWISE
                  LEGALLY PERMITTED TO USE THE SITE AND THE SERVICES IN YOUR
                  JURISDICTION AND THAT THE COMPANY IS NOT LIABLE FOR YOUR
                  COMPLIANCE WITH SUCH APPLICABLE LAWS.
                </p>
                <h5 className="privacypolicymainhead">
                  2. THE SERVICES/PRODUCTS
                </h5>
                <h6 className="privacypolicylowerlisthead">
                  2.1. Digital Wallet Services.
                </h6>
                <p className="privacypolicymainpara marginbot">
                  We may provide you with a digital wallet services (the
                  “Digital Wallet” and the “Digital Wallet Services”,
                  respectively). The Digital Wallet will enable you to receive,
                  store, send and perform additional actions, all with respect
                  to digital currencies. In addition, you may withdraw funds
                  contained in your Digital Wallet into your bank account, and
                  in such case the funds will be automatically exchanged into
                  fiat currency and transferred into your account. The terms
                  contained in this Agreement shall govern all uses in the
                  Digital Wallet.
                </p>

                <div className="termsflex">
                  <h6 className="termsnumber">2.1.1</h6>
                  <p className="termspara">
                    Funds may be transferred to your Digital Wallet from third
                    party wallets or accounts, or be withdrawed into your
                    Digital Wallet from your account in the Site, and sent from
                    your Digital Wallet to external digital wallets. Such funds
                    may only be in digital currencies supported by the Digital
                    Wallet. It is your responsibility to check prior to any
                    transfer of funds whether the Digital Wallet and/or any
                    third party digital wallet supports the digital currencies
                    you wish to transfer. The Digital Wallet will show the funds
                    transferred into it once it was received into the Digital
                    Wallet.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.2</h6>
                  <p className="termspara">
                    In order to sale, transfer, buy, convert or exchange any
                    digital currencies please comply with the relevant
                    instructions on the Site. Any such transaction shall be
                    carried out promptly upon receipt of the relevant order,
                    provided, however, that extended processing times or delays
                    may occur, inter alia, due to technical issues or otherwise.
                    By instructing the execution of a transaction, you agree and
                    authorize to perform the transaction under the terms set
                    forth in the website (subject to a reasonable deviation
                    which may occur due to changes in the exchange rate or Third
                    Party Fees as set forth below.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.3</h6>
                  <p className="termspara">
                    Please note that we are not liable for any currency which
                    can be traded, sold, purchased, exchanged, converted or
                    otherwise available at the Site or the Digital Wallet. Such
                    currencies may be affected by third parties and market
                    conditions, and we cannot guarantee the volatility or
                    reliability level of such currencies. Prior to conducting
                    any transaction, please be sure to check and research each
                    currency you trade, use, transact, purchase or hold, as it
                    may be volatile, be subject to certain limitations or
                    restrictions or be part of fraudulent activity or a scam. We
                    may also refuse to process or cancel any pending transaction
                    as required by law, regulation or any court or any other
                    governmental authority applicable to our activities, for
                    instance, if there is suspicion of money laundering,
                    terrorist financing, fraud, or any other financial crime.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.4</h6>
                  <p className="termspara">
                    Any order or instruction made by you shall be final and
                    binding in accordance with its terms and may not be revoked
                    or cancelled, unless otherwise specified in the order’s
                    terms. In addition, any such order may not be processed if
                    the balance contained in your Digital Wallet is insufficient
                    to the completion of the transaction (including the payment
                    of any fees to the Company or to third parties, such as gas
                    fees).
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.5</h6>
                  <p className="termspara">
                    In addition, you hereby agree and authorize the Company, at
                    its sole discretion, to cancel, not approve, or to refuse to
                    process any transaction, inter alia, due to legal
                    restrictions or requirements under applicable law. The
                    Company may also require, before the approval of any
                    transaction, as a condition for the approval and processing
                    of any transaction, to require the conduct of examinations
                    for the purposes of preventing fraud, money laundering,
                    terrorist financing and other financial crimes, and as
                    required by applicable law. In the event a transaction could
                    not be completed, please try to re-attempt to initiate the
                    transaction (please note that the rates and fees applicable
                    to the new transaction will be the then current rates and
                    fees).
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.6</h6>
                  <p className="termspara">
                    Any transaction conveyed from your Digital Wallet shall be
                    deemed to be known to and approved by you. The Company shall
                    not be held liable or responsible for any orders made from
                    your Digital Wallet but not known to or authorized by you.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.7</h6>
                  <p className="termspara">
                    You may purchase digital currencies supported by the Digital
                    Wallet by linking a valid payment method to your Digital
                    Wallet. You authorise the Company to debit funds using your
                    selected payment method(s) to complete your purchase.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.8</h6>
                  <p className="termspara">
                    Certain actions, including (but not limited to) purchase,
                    sale, exchange and trade of digital currencies shall require
                    the payment of a certain fee (the “Transaction Fees”). Such
                    Transaction Fees shall be brought to your attention and
                    approval prior to any applicable transaction. The
                    Transaction Fees amount may be changed, at the Company’s
                    sole discretion, from time to time without prior notice. By
                    instructing the execution of a transaction, you agree and
                    authorize the payment of any Transaction Fees related to
                    such transaction.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.9</h6>
                  <p className="termspara">
                    In addition, certain actions, including (but not limited to)
                    purchase, sale, exchange and trade of digital currencies
                    shall require the payment of a fees imposed by third
                    parties, such as the networks or blockchain on which the
                    transactions are made (the “Third Party Fees”). Such Third
                    Party Fees shall be brought to your attention and approval
                    prior to any applicable transaction and shall be determined
                    by quotes given by the relevant third party and may change
                    at any time. By instructing the execution of a transaction,
                    you agree and authorize the payment of any Third Party Fees
                    related to such transaction.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.10</h6>
                  <p className="termspara">
                    Sale, purchase exchange and/or conversion of digital
                    currencies are subject to the conversion rate applicable to
                    the sold, purchased exchanged or converted currencies. Such
                    exchange rate may change frequently, and all transactions
                    shall be made at the spot exchange rate applicable to such
                    transaction. By authorizing a transaction, you agree to the
                    exchange rate, as shall be determined by the Site, based on
                    quotes available on the market.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.11</h6>
                  <p className="termspara">
                    Please note that the Digital Wallet and the Digital Wallet
                    Services only support specific currencies which may change
                    from time to time. The currently supported currencies are
                    the currencies available on the Digital Wallet. Please
                    confirm that Digital Wallet supports the currency you intend
                    to transact with before the transaction, and avoid
                    transacting in the Digital Wallet with any currency not
                    supported. Please note that we may terminate our support on
                    any currency at any time at our sole discretion, without any
                    prior notice, although under normal circumstances we will
                    attempt to provide our customers with a 7 days’ notice,
                    which shall be available at our website, prior to
                    termination of support in any currency. Termination of
                    support in currencies may also occur due to third party’s
                    (such as the network, blockchain or the issuer of the
                    currency) actions, which we do not have control over. If
                    notice of termination of support is provided, please convert
                    or withdraw from your wallet any terminating currencies
                    prior to the termination of support on such currencies.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.12</h6>
                  <p className="termspara">
                    Please also note that certain digital currencies are only
                    support by particular network(s) or blockchain(s).
                    Transacting with such currencies via non-supporting
                    network(s) or blockchain(s) may result in a permanent and
                    irrevocable lose of the funds or currencies transacted with.
                    It is your responsibility to check that the currencies you
                    intend to transact with are supported by the network(s) or
                    blockchain(s) you intend to use prior to the conduct of any
                    transaction.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.1.13</h6>
                  <p className="termspara">
                    We may also offer you an option to stake certain currencies,
                    either on our own behalf or for third parties, in
                    consideration for a certain fee. If you shall agree to
                    accept a staking offer, you shall be rewarded for
                    transactions actually validated with the use of the funds
                    staked by you, in an amount determined by the protocols of
                    the applicable validated network. Such reward shall be
                    subject to the payment of commission to us in a percentage
                    which will be determined and made available to you on our
                    Site. Please note that if you shall provide an instruction
                    of staking, the funds you choose to stake may be locked, and
                    the availability thereof shall be restricted, for certain
                    periods of time, as stated by the applicable network
                    protocol. Please note that your right for a reward for
                    staking is subject to the actual receipt of such reward by
                    us.
                  </p>
                </div>
                <h5 className="privacypolicylowerlisthead">
                  2.2. Pioneers Club
                </h5>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.1</h6>
                  <p className="termspara">
                    We offer to provide a membership in our club entitled the
                    “Pioneers”. Subscription to the Pioneers club will be made
                    by purchasing a membership from the Site, or purchasing the
                    Pioneers club member card from in the secondary market.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.2</h6>
                  <p className="termspara">
                    We will offer club memberships at a limited number to be
                    determined by us at a minting event which will dates will be
                    announced on the Site. We do not guarantee that all
                    applications for purchase of memberships shall be respected.
                    In addition, after the minting event, one Pioneers club
                    membership will be auctioned every day and sold to the
                    highest bidder.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.3</h6>
                  <p className="termspara">
                    Pioneers club membership will entitle its holder to a
                    membership in our exclusive Pioneers club, represented by an
                    NFT. Pioneer club members may also receive, at our sole
                    discretion, certain gifts, such as the benefits specified in
                    the Site (please note that as some of the benefit require
                    additional development and set up times, some benefits will
                    be granted only a certain period following the minting
                    event). We may also grant the Pioneers club members
                    additional awards and benefits from time to time not
                    specified in the Site at our sole discretion. Among others,
                    the purchasers of a Pioneers club membership (in the primary
                    market) will be granted with a certain amount of our tokens
                    entitled “tomi” (“TOMI tokens”). TOMI Tokens may be
                    purchased from various cryptocurrency exchanges. The
                    purchase and holding of TOMI Tokens shall be made at your
                    sole discretion. Please note that the TOMI Tokens are
                    strictly utility tokens intended to serve as the native
                    token for the tomi ecosystem and can be used to facilitate
                    payments on the tomiNet, to pay fees on the tomi products
                    and to vote on the different decentralized autonomous
                    organizations related to the tomi ecosystem. We specifically
                    note that TOMI Tokens are not and should not be deemed or
                    viewed as an investment in the tomi project.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.4</h6>
                  <p className="termspara">
                    We specifically note that by purchasing a Pioneer club
                    membership, you purchase digital products which entitles you
                    to join our community and to all benefits mentioned on our
                    Site, but you shall not be considered, in any matter, an
                    investor, shareholder or otherwise an interest holder in
                    tomi or in any company that operates tomi or that holds
                    rights in the intellectual property underlying our project.
                    We value our community and know that the community is
                    crucial for tomi’s success, and therefore we will listen and
                    consider offers raised by the community. However, tomi was
                    fully funded by the private funds of its founders. As such,
                    tomi’s management will have the sole and complete discretion
                    to manage its business in the best way it deems correct. By
                    purchasing a tomi Pioneers club membership, you waive any
                    claims to tomi or to other third parties regarding your
                    right to influence or vote on any managerial decisions
                    (including tomi’s use of funds) (other than, of course, the
                    tomi DAO, which will be managed by its members).
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.5</h6>
                  <p className="termspara">
                    No refunds are permitted except with respect to any
                    statutory warranties or guaranties that cannot be excluded
                    or limited by law.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.6</h6>
                  <p className="termspara">
                    Please note that the founding team will be granted with
                    Pioneer club memberships.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.7</h6>
                  <p className="termspara">Whitelist</p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.7.1</h6>
                  <p className="termspara">
                    Since Pioneers club memberships are available up to a
                    limited mystery cap we cannot assure that all demand for
                    Pioneers club memberships will be met. We therefore created
                    the whitelist, which intends to ensure that all early
                    subscribers will have a right to purchase memberships by
                    giving them a priority in the minting event.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.7.2</h6>
                  <p className="termspara">
                    Each whitelist subscriber will choose the amount of Pioneers
                    club membership(s) will have priority in purchasing
                    Pioneers. Specific instructions on how to receive the
                    Pioneers subscribed to as part of the Whitelist stage will
                    be sent to the subscribers in the email specified by them in
                    their registration process.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.7.3</h6>
                  <p className="termspara">
                    If a whitelist subscriber have not paid the Pioneer purchase
                    price in full, the applicable subscriber must complete the
                    payment up to 24 hours prior to public minting, and not
                    later than January 14, 2023. Any Pioneer not paid for in
                    full prior to the abovementioned date will be sold as part
                    of the public minting phase, and the upfront payment paid on
                    account of the subscription will not be refunded.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.7.4</h6>
                  <p className="termspara">
                    Please note that the whitelist registration (including the
                    number of memberships you subscribe for) is binding and
                    non-reversible.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.8</h6>
                  <p className="termspara">Initial Public Minting</p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.8.1</h6>
                  <p className="termspara">
                    Any Pioneer not subscribed for by whitelist subscribers (but
                    not more than 1,500 Pioneers) will be sold to the public at
                    a minting event on a date to be announced (the “Minting
                    Event”).
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.8.2</h6>
                  <p className="termspara">
                    The sale of Pioneers to purchasers from the public shall be
                    made in the Minting Event, in consideration for a purchase
                    price to be set by us at our discretion.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.8.3</h6>
                  <p className="termspara">
                    The allocation of Pioneers to purchasers shall be on a
                    “first come first serve” basis. Notwithstanding, the number
                    of Pioneers that can be purchased by any single wallet is no
                    more than 10 Pioneers per wallet.
                  </p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.9</h6>
                  <p className="termspara">Daily Pioneer Auction</p>
                </div>
                <div className="termsflex">
                  <h6 className="termsnumber">2.2.9.1</h6>
                  <p className="termspara">
                    Pioneers may be sold at daily auctions, the initial amounts
                    of Pioneers to be auctioned upon and the number of tokens to
                    be issued to the highest bidder(s) in the daily auctions
                    shall be determined by us, but please note that since tomi
                    is a decentralized project, the governing community, through
                    the tomiDAO, may change the auctions’ mechanism, standards,
                    number of Pioneers to be auctioned upon, number of tokens to
                    be issued and other benefits to be awarded to the winning
                    bidders and any other criteria, at its sole discretion.
                  </p>
                </div>
                <h5 className="privacypolicymainhead">3. Your Account</h5>
                <p className="privacypolicymainpara marginbot">
                  You may use the Site by simply connecting your digital wallet.
                  Once your wallet is connected, you may create an account with
                  the Site (the "Account"). The provision of additional
                  information is optional, however, we may, at our sole
                  discretion, require you to provide additional information
                  and/or documents. If you do not provide complete and accurate
                  information and/or documents in response to such a request, we
                  may refuse to provide you with some or all of the Services.
                </p>
                <p className="privacypolicymainpara marginbot">
                  Your Account is subject to the following conditions:
                </p>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Access: you shall be the only user in your Account. You may
                    not sell, rent, lease, permit usage, transfer in any way or
                    grant access to your Account to any person without our prior
                    written permission.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Security: you understand and agree that you are solely
                    responsible for maintaining the security of your account and
                    control over any user names, passwords, or any other codes
                    that you use to access the Services. Any unauthorized access
                    to your account by third parties could result in the loss or
                    theft of cryptocurrency, tokens, NFT and/or your Digital
                    Wallet and/or funds held in your account and any associated
                    accounts, including your linked bank account(s) and credit
                    card(s). You understand and agree that you will not hold us
                    responsible for managing and maintaining the security of
                    your account, or for losing your password. You further
                    understand and agree that we are not responsible (and you
                    will not hold us responsible) for any unauthorized access to
                    or use of your account. You are responsible for monitoring
                    your account and for keeping your password. If you notice
                    any unauthorized or suspicious activity in your account,
                    please notify us immediately.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Trading Fees: by using, buying, placing bids or selling
                    tokens or other digital assets on the Site, you agree to pay
                    all applicable fees (including but not limited to fees
                    imposed by third parties such as gas fees which may be
                    imposed or recommended by the ERC-20 network) and you
                    authorize us to automatically deduct fees directly from your
                    payment. The amounts of fees payable will be provided to you
                    prior to the payment of such fees
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Communication: you agree and understand that we will
                    communicate with you via electronic means. You agree that
                    any notices, agreements, disclosures, or other
                    communications delivered to your email address on record is
                    considered valid.
                  </li>
                  <li className="privacypolicytopheadlist">
                    Unclaimed Property: if the Company is holding funds in your
                    account and/or Digital Wallet and has no record of your use
                    of the Service for several years, we may be required, upon
                    passage of applicable time periods, to report these funds as
                    unclaimed property in accordance with the abandoned property
                    and escheat laws. If this occurs, we will use reasonable
                    efforts to give you written notice. If you fail to respond
                    within seven business days or as required by law, we may be
                    required to deliver any such funds to the applicable state
                    or jurisdiction as unclaimed property. We reserve the right
                    to deduct a dormancy fee or administrative fee from such
                    unclaimed funds, as permitted by applicable law.
                  </li>
                </ol>
                <h5 className="privacypolicymainhead">4. Account Suspension</h5>
                <p className="privacypolicymainpara">
                  You agree that the Company has the right to immediately
                  suspend your account and/or prevent your wallet address from
                  accessing the Site and/or pause or cancel your access to the
                  Services, or close your account if we suspect, in our sole
                  discretion, that (1) your account is being used for money
                  laundering or any illegal activity; (2) you have concealed or
                  provided false identification information or other details;
                  (3) you have engaged in fraudulent activity; or (4) you have
                  engaged in transactions in violation of this Agreement.
                </p>
                <h5 className="privacypolicymainhead">5. Communication</h5>
                <p className="privacypolicymainpara marginbot">
                  You agree that we may send you promotional communications by
                  email, including, but not limited to, newsletters, special
                  offers, surveys, and other news and information we think will
                  be of interest to you.
                </p>
                <p className="privacypolicymainpara marginbot">
                  You agree that we may send you communications by email or text
                  message that pertain to the Site, the Services, and other
                  communications that pertain to your interaction with the Site.
                </p>
                <p className="privacypolicymainpara">
                  You may opt out of promotional communications at any time by
                  following the instructions provided therein.
                </p>
                <h5 className="privacypolicymainhead">6. Ownership</h5>
                <p className="privacypolicymainpara marginbot">
                  Unless otherwise indicated in writing by us, the Site, all
                  content, and all other materials contained therein, including,
                  without limitation, the Company's logo, and all designs, text,
                  graphics, pictures, information, data, software, sound files,
                  other files, and the selection and arrangement thereof
                  (collectively, “Company's Content”) are the proprietary
                  property of the Company or our affiliates, licensors, or
                  users, as applicable. The Company's logo and any of our
                  products or service names, logos, or slogans that may appear
                  on the Site or elsewhere are trademarks of the Company or our
                  affiliates, and may not be copied, imitated or used, in whole
                  or in part, without our prior written permission.
                </p>
                <p className="privacypolicymainpara marginbot">
                  You may not use any Company Content to link to the Site or
                  Content without our express written permission. You may not
                  use framing techniques to enclose any Company Content without
                  our express written consent. In addition, the look and feel of
                  the Site and Content, including without limitation, all page
                  headers, custom graphics, button icons, and scripts constitute
                  the service mark, trademark, or trade dress of the Company and
                  may not be copied, imitated, or used, in whole or in part,
                  without our prior written permission.
                </p>
                <p className="privacypolicymainpara">
                  Notwithstanding anything to the contrary herein, you
                  understand and agree that you shall have no ownership or other
                  property interest in your account, and you further agree that
                  all rights in and to your account are and shall forever be
                  owned by and inure to the benefit of the Company.
                </p>
                <h5 className="privacypolicymainhead">
                  7. Intellectual Property
                </h5>
                <p className="privacypolicymainpara marginbot">
                  All trademarks, product names, and logos on the Site are the
                  property of the Company and may not be copied, imitated, or
                  used, in whole or in part, without the permission of the
                  Company. Without limiting the foregoing, if you believe that
                  third-party material hosted by the Company infringes your
                  copyright or trademark rights, please file a notice of
                  infringement by contacting the Company.
                </p>
                <p className="privacypolicymainpara marginbot">
                  In such event, please provide the Company with the following
                  information: (1) an electronic or physical signature of the
                  person authorized to act on behalf of the owner of the
                  copyright interest; (2) a description of the copyrighted work
                  that you claim has been infringed; (3) a description of the
                  location on Site of the material that you claim is infringing;
                  (4) your address, telephone number and e-mail address; (5) a
                  written statement by you that you have a good faith belief
                  that the disputed use is not authorized by the copyright
                  owner, its agent or the law; and (6) a statement by you, made
                  under penalty of perjury, that the above information in your
                  notice is accurate and that you are the copyright owner or
                  authorized to act on the copyright owner’s behalf.
                </p>
                <p className="privacypolicymainpara">
                  The Company’s policy is to suspend or terminate the account of
                  repeat infringers. The Company’s response may depend on
                  relevant aggravating or mitigating circumstances, but
                  generally we will terminate an account if it is the subject of
                  three valid infringement notices.
                </p>
                <h5 className="privacypolicymainhead">
                  8. Your Use of the Site
                </h5>
                <p className="privacypolicymainpara marginbot">
                  We hereby grant you a limited, non-exclusive,
                  non-transferable, revocable license to access and use the
                  Site, the Services and Company’s Content. Our grant of such
                  license is subject to the following conditions:
                </p>
                <ol>
                  <li className="privacypolicytopheadlist marginbot">
                    Unlawful Activity: you agree not to engage, or assist, in
                    any activity that violates any law, statute, ordinance,
                    regulation, or sanctions program, including but not limited
                    to the U.S. Department of Treasury’s Office of Foreign
                    Assets Control (“OFAC”), or that involves proceeds of any
                    unlawful activity.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Abusive Activity: you agree not to engage in any activity
                    that poses a threat to the Company, the Site or the
                    Services, for example by distributing a virus or other
                    harmful code, or through unauthorized access to the Site or
                    other users’ accounts.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Inappropriate Behavior: you agree not to interfere with
                    other users’ access to or use of the Services.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Communication: you agree not to communicate with other users
                    for purposes of (1) sending unsolicited advertising or
                    promotions, requests for donations, or spam; (2) harassing
                    or abusing other users; (3) interfering with transactions of
                    other users. You agree not to use data collected from the
                    Site to contact individuals, companies, or other persons or
                    entities outside the Site for any purpose, including but not
                    limited to marketing activity.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Fraud: you agree not to engage in any activity which
                    operates to defraud the Company, others users, or any other
                    person; or to provide any false, inaccurate, or misleading
                    information to the Company.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Gambling: you agree not to utilize the Services to engage in
                    any lottery, bidding fee auctions, contests, sweepstakes, or
                    other games of chance
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Taxes: Neither the Company nor any other affiliate or
                    related party thereof is responsible for determining the
                    withholding, sales, use, value added, transfer or other
                    taxes, together with any interest and penalties imposed with
                    respect thereto (“Taxes”), that apply to your transactions.
                    You agree that you are solely responsible for determining
                    what, if any, Taxes apply to your transactions and to
                    withhold, collect, report and remit the correct amounts of
                    Taxes to the appropriate taxing authorities. Unless
                    otherwise indicated on an applicable invoice, any amounts
                    due in respect of a transaction are exclusive of sale, use,
                    value added or similar Taxes (“Sales Taxes”) which shall be
                    your responsibility. Any payments with respect to your
                    transactions shall be made without deduction or withholding
                    for any Taxes, except as required by applicable law. If any
                    applicable law requires the deduction or withholding of any
                    Tax (a “Withholding Tax”) from any such payment then the sum
                    payable by you shall be increased as necessary so that after
                    such deduction or withholding has been made the amount
                    received is equal to the sum that would have been received
                    had no such deduction or withholding been made. Upon our
                    request, you agree to promptly provide a properly executed
                    Internal Revenue Service Form W-9 or applicable Internal
                    Revenue Service W-8 and any other tax form that is
                    reasonably required by us so to comply with our tax
                    reporting obligations.
                  </li>
                  <li className="privacypolicytopheadlist marginbot">
                    Additional Prohibitions: you agree not to (1) distribute,
                    publish, broadcast, reproduce, copy, retransmit, or publicly
                    display any Content; (2) modify or create derivative works
                    from the Site or Services, or any portion thereof; (3) use
                    any data mining, robots, or similar data gathering or
                    extraction methods on the Site or Services; (4) download any
                    portion of the Site or Services, other than for purposes of
                    page caching, except as expressly permitted by us.
                  </li>
                </ol>
                <p className="privacypolicymainpara">
                  If you are unsure whether a contemplated use would violate
                  This Agreement, please contact us at the address listed below
                  under Contact Information.
                </p>
                <h5 className="privacypolicymainhead">9. Privacy</h5>
                <p className="privacypolicymainpara">
                  You acknowledge and agree to the collection, use, and
                  disclosure of your personal information in accordance with the
                  Company’s Privacy Policy, which is incorporated into this User
                  Agreement by reference.
                </p>
                <h5 className="privacypolicymainhead">10. Modifications</h5>
                <p className="privacypolicymainpara">
                  You agree and understand that we may modify part or all of the
                  Services or the Site or the Services without notice.
                </p>
                <h5 className="privacypolicymainhead">
                  11. Unauthorized Usage
                </h5>
                <p className="privacypolicymainpara marginbot">
                  Due to legal restrictions, none of our Services, Products or
                  the technology developed by us is intended to be used by
                  Unauthorized Persons (as defined below), and specifically by
                  U.S. Persons (as defined below), and any access or usage by
                  such is strictly unauthorized and forbidden. If you
                  Unauthorized Person or a U.S. Person you may not hold any TOMI
                  Tokens or NFT issued by us, use or access our Site, platform,
                  Services, Products or technology, and if you already access or
                  use any of the above you are required to cease using the
                  abovementioned immediately and remove from your devices any
                  software related to tomi.We practice our best efforts to deny
                  access to any Unauthorized Person or a U.S. Person. You
                  acknowledge and agree that if you are an Unauthorized Person
                  or U.S. Person you are not permitted to use our Site, platform
                  or any of our Services or Products and are required to leave
                  the Site and cease to consume any Services or Products from us
                  immediately. You may not try to circumvent or bypass this
                  restriction.
                </p>
                <p className="privacypolicymainpara marginbot">
                  Any use in the company’s Site, platform, Services, Products or
                  technology in contradiction with this User Agreement, and
                  specifically with this Section 11 carries significant risks,
                  and you shall be solely liable for any claims, damages,
                  awards, judgments, losses, liabilities, obligations,
                  penalties, interest, fees, expenses (including, without
                  limitation, attorneys’ fees and expenses) and costs
                  (including, without limitation, court costs, costs of
                  settlement and costs of pursuing indemnification and
                  insurance) and shall indemnify and hold tomi harmless for any
                  claims, damages, awards, judgments, losses, liabilities,
                  obligations, penalties, interest, fees, expenses (including,
                  without limitation, attorneys’ fees and expenses) and costs
                  (including, without limitation, court costs, costs of
                  settlement and costs of pursuing indemnification and
                  insurance) suffered by it in connection with same. For the
                  avoidance of doubt, this Section shall not derogate from any
                  other liability (including limitation thereof), disclaimer,
                  restriction, indemnification obligation or other term of this
                  User Agreement.
                </p>
                <p className="privacypolicymainpara marginbot">
                  In this User Agreement: “Prohibited Person” means any U.S.
                  Person and any Sanctioned Person. ““U.S. Person” means: -a
                  U.S. Citizen or U.S. Resident;-a corporation, partnership, or
                  other entity established or organized in or under the Laws of
                  the United States;-any estate of a decedent who was a U.S.
                  Citizen or U.S. Resident;-any trust if (i) a court within the
                  United States is able to exercise primary supervision over the
                  administration of the trust, and (ii) one or more United
                  States Persons have the authority to control all substantial
                  decisions of the trust;-any Person organized or incorporated
                  outside the United States and the Territory or Insular
                  Possession of the United States in which any of the foregoing,
                  whether singularly or in the aggregate, directly or indirectly
                  (i) holds a 50 percent or greater equity interest by votes or
                  value, (ii) holds a majority of seats or memberships on the
                  board of directors of the entity, or (iii) authorizes,
                  establishes, directs, or otherwise controls the actions,
                  policies, personnel decisions, or day-to-day operations of the
                  Person; or“Sanctioned Person” refers to any person or digital
                  tokens address that is: (i) specifically listed in any
                  Sanctions List; (ii) directly or indirectly owned 50 percent
                  or more by any person or group of persons in the aggregate, or
                  a digital tokens wallet associated with such person or
                  persons, referred to in any Sanctions List; or (iii) that is
                  subject to any government approval or otherwise sanctioned,
                  restricted, or penalized under applicable economic or
                  financial sanctions of any type, anti money laundering laws,
                  or counter terrorist financing laws;
                </p>
                <p className="privacypolicymainpara">
                  “Sanctions List” means the “Specially Designated Nationals and
                  Blocked Persons” (“SDN”) List and any other similar list in
                  any jurisdiction, including the “Sectoral Sanctions
                  Identifications List”, published by OFAC; the Section 311
                  Special Measures for Jurisdictions, Financial Institutions, or
                  International Transactions of Primary Money Laundering Concern
                  published by FinCEN.
                </p>
                <h5 className="privacypolicymainhead">12. Risks</h5>
                <p className="privacypolicymainpara marginbot">
                  Please note the following risks in accessing or using the Site
                  and/or the Services: The price and liquidity of blockchain
                  assets, including TOMI Tokens are extremely volatile and may
                  be subject to large fluctuations; Fluctuations in the price of
                  other digital assets could materially and adversely affect the
                  cryptocurrency market (including the TOMI Tokens), which may
                  also be subject to significant price volatility; Legislative
                  and regulatory changes or actions at the state, federal, or
                  international level may adversely affect the use, transfer,
                  exchange, and value of digital assets (including the TOMI
                  Tokens); cryptocurrencies (including the TOMI Tokens) are not
                  legal tender and are not backed by the government;
                  Transactions in cryptocurrencies (including the TOMI Tokens)
                  may be irreversible, and, accordingly, losses due to
                  fraudulent or accidental transactions may not be recoverable;
                  Some transactions in cryptocurrencies (including the TOMI
                  Tokens) shall be deemed to be made when recorded on a public
                  ledger, which is not necessarily the date or time that you
                  initiated the transaction; The value of cryptocurrencies
                  (including the TOMI Tokens) may be derived from the continued
                  willingness of market participants to exchange fiat currency
                  or digital assets for cryptocurrencies (including the TOMI
                  Tokens), which may result in the potential for permanent and
                  total loss of value of a cryptocurrency (including the TOMI
                  Tokens) should the market for that cryptocurrency (including
                  the TOMI Tokens) disappear; The nature of digital currencies
                  (including the TOMI Tokens) may lead to an increased risk of
                  fraud or cyber-attack, and technical difficulties experienced
                  by the Company may limit or prevent the access to or use of
                  your digital assets; Changes to Third Party Sites (discussed
                  below) may create a risk that your access to and use of the
                  Site will suffer.You agree and understand that you are solely
                  responsible for determining the nature, potential value,
                  suitability, and appropriateness of these risks for yourself,
                  and that the Company does not give advice or recommendations
                  regarding Services (including the TOMI Tokens), including the
                  suitability and appropriateness of, and investment or purchase
                  strategies (including with respect to the purchase of TOMI
                  Tokens). You agree and understand that you access and use the
                  Site and the Services (including the TOMI Tokens) at your own
                  risk; however, this brief statement does not disclose all of
                  the risks associated with usage in the Site or Services, and
                  transacting with digital assets. You agree and understand that
                  the Company will not be responsible for any communication
                  failures, disruptions, errors, distortions or delays you may
                  experience when using the Site, the TOMI Tokens or the
                  Services, however caused.{" "}
                </p>
                <p className="privacypolicymainpara">
                  You agree and understand that you are solely responsible for
                  determining the nature, potential value, suitability, and
                  appropriateness of these risks for yourself, and that the
                  Company does not give advice or recommendations regarding
                  Services (including the TOMI Tokens), including the
                  suitability and appropriateness of, and investment or purchase
                  strategies (including with respect to the purchase of TOMI
                  Tokens). You agree and understand that you access and use the
                  Site and the Services (including the TOMI Tokens) at your own
                  risk; however, this brief statement does not disclose all of
                  the risks associated with usage in the Site or Services, and
                  transacting with digital assets. You agree and understand that
                  the Company will not be responsible for any communication
                  failures, disruptions, errors, distortions or delays you may
                  experience when using the Site, the TOMI Tokens or the
                  Services, however caused.
                </p>
                <h5 className="privacypolicymainhead">13. Third Parties</h5>
                <p className="privacypolicymainpara marginbot">
                  The Company may rely on third-party platforms to perform
                  transactions or provide Services. Our Site or Services may
                  contain links to third-party websites or applications
                  (collectively, “Third Party Sites”). The Company does not own
                  or control Third Party Sites. You understand and agree that
                  your use of any Third Party Site is subject to any terms of
                  use and/or privacy policy provided by such Third Party Site.
                  The Company is not a party to any such agreement. You should
                  review any terms of use and/or privacy policy provided by such
                  Third Party Site and should make whatever investigation you
                  feel necessary or appropriate before proceeding with any
                  transaction with any third party.
                </p>
                <p className="privacypolicymainpara">
                  The Company provides these Third Party Sites only as a
                  convenience and does not review, approve, monitor, endorse,
                  warrant, or make any representations with respect to Third
                  Party Sites, or their products or services. You use all links
                  in Third Party Sites at your own risk. When you leave our
                  Site, our Agreement and policies no longer govern. We may, but
                  are not obligated to, warn you that you are leaving our Site.
                </p>
                <h5 className="privacypolicymainhead">14. Disclaimers</h5>
                <p className="privacypolicymainpara marginbot">
                  EXCEPT AS EXPRESSLY PROVIDED TO THE CONTRARY IN A WRITING BY
                  THE COMPANY, THE SITE, CONTENT CONTAINED THEREIN, THE TOMI
                  TOKENS AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS
                  AVAILABLE” BASIS WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND,
                  EITHER EXPRESS OR IMPLIED. THE COMPANY (AND ITS SUPPLIERS)
                  MAKE NO WARRANTY THAT THE SITE WILL (1) MEET YOUR
                  REQUIREMENTS; (2) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY,
                  SECURE, OR ERROR-FREE BASIS; OR (3) BE ACCURATE, RELIABLE,
                  COMPLETE, LEGAL, OR SAFE.
                </p>
                <p className="privacypolicymainpara marginbot">
                  WE WILL NOT BE LIABLE FOR ANY LOSS OF ANY KIND FROM ANY ACTION
                  TAKEN OR TAKEN IN RELIANCE ON MATERIAL OR INFORMATION,
                  CONTAINED ON THE SITE. THE COMPANY DOES NOT REPRESENT OR
                  WARRANT THAT CONTENT ON THE SITE IS ACCURATE, COMPLETE,
                  RELIABLE, CURRENT OR ERROR-FREE.
                </p>
                <p className="privacypolicymainpara marginbot">
                  WHILE THE COMPANY ATTEMPTS TO MAKE YOUR ACCESS TO AND USE OF
                  THE SITE AND CONTENT SAFE, THE COMPANY CANNOT AND DOES NOT
                  REPRESENT OR WARRANT THAT THE SITE, CONTENT, THE TOMI TOKENS,
                  THE SERVICES OR OUR SERVERS ARE FREE OF VIRUSES OR OTHER
                  HARMFUL COMPONENTS. WE CANNOT GUARANTEE THE SECURITY OF ANY
                  DATA THAT YOU DISCLOSE ONLINE. YOU ACCEPT THE INHERENT
                  SECURITY RISKS OF PROVIDING INFORMATION AND DEALING ONLINE
                  OVER THE INTERNET AND WILL NOT HOLD US RESPONSIBLE FOR ANY
                  BREACH OF SECURITY UNLESS IT IS DUE TO OUR GROSS NEGLIGENCE.
                </p>
                <p className="privacypolicymainpara marginbot">
                  WE WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS AND
                  TAKE NO RESPONSIBILITY FOR, AND WILL NOT BE LIABLE TO YOU FOR,
                  ANY USE OF THE SERVICS OR THE USE OR TRADE IN THE TOMI TOKENS,
                  INCLUDING BUT NOT LIMITED TO ANY LOSSES, DAMAGES OR CLAIMS
                  ARISING FROM: (1) USER ERROR SUCH AS FORGOTTEN PASSWORDS,
                  INCORRECTLY CONSTRUCTED TRANSACTIONS, OR MISTYPED ADDRESSES;
                  (2) SERVER FAILURE OR DATA LOSS; (3) CORRUPTED WALLET FILES;
                  (4) UNAUTHORIZED ACCESS TO APPLICATIONS; (5) ANY UNAUTHORIZED
                  THIRD PARTY ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE
                  OF VIRUSES, PHISHING, BRUTEFORCING OR OTHER MEANS OF ATTACK
                  AGAINST THE SITE, THE TOMI TOKENS OR THE SERVICES.
                </p>
                <p className="privacypolicymainpara marginbot">
                  TOMI TOKENS ARE INTANGIBLE DIGITAL ASSETS. THEY EXIST ONLY BY
                  VIRTUE OF THE OWNERSHIP RECORD MAINTAINED IN THE ETHEREUM
                  NETWORK. ANY TRANSFER OF TITLE THAT MIGHT OCCUR IN ANY UNIQUE
                  DIGITAL ASSET OCCURS ON THE DECENTRALIZED LEDGER WITHIN THE
                  ETHEREUM PLATFORM. WE DO NOT GUARANTEE THAT THE COMPANY OR ANY
                  RELATED PARTY CAN EFFECT THE TRANSFER OF TITLE OR RIGHT IN ANY
                  TOMI TOKENS.
                </p>
                <p className="privacypolicymainpara marginbot">
                  DUE TO THE VOLATILE NATURE OF THE DIGITAL CURRENCIES MARKET IN
                  GENERAL AND THE EXTREMELY HIGH RISK ASSOCIATED WITH NEWLY
                  ISSUED TOMI TOKENS IN PARTICULAR, THE COMPANY CANNOT GUARANTEE
                  THE VALUE OF THE CURRENCIES OR TOKENS EARNED OR HELD AS PART
                  OF THE SERIVCES (INCLUDING THE TOMI TOKENS OR THAT THE TOMI
                  TOKENS WILL ACCRUE ANY VALUE AT ANY TIME IN THE FUTURE), AND
                  ANY RISK OF FLUCTUATION OR REDUCTION IN PRICE SHALL BE BORNE
                  SOLELY BY YOU.
                </p>
                <p className="privacypolicymainpara marginbot">
                  The Company is not responsible for sustained losses due to
                  vulnerability or any kind of failure, abnormal behaviour of
                  software (e.g., wallet, smart contract), blockchains, or any
                  other related features (including with respect to the TOMI
                  Tokens). The Company is not responsible for sustained losses
                  due to late reports by developers or representatives (or no
                  report at all) of any issues with the blockchain supporting
                  the Services (including the TOMI Tokens) including forks,
                  technical node issues or any other issues having fund losses
                  as a result.
                </p>
                <p className="privacypolicymainpara marginbot">
                  Nothing in this Agreement shall exclude or limit liability of
                  either party for fraud, death or bodily injury caused by
                  negligence, violation of laws, or any other activity that
                  cannot be limited or excluded by legitimate means.
                </p>
                <p className="privacypolicymainpara marginbot">
                  TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY
                  DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
                  IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                  ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
                  PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE SITE AND CONTENT
                  CONTAINED THEREIN.
                </p>
                <p className="privacypolicymainpara">
                  THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE
                  EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <h5 className="privacypolicymainhead">
                  15. Limitation of Liability
                </h5>
                <p className="privacypolicymainpara marginbot">
                  TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE
                  COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST
                  PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                  SPECIAL OR PUNITIVE DAMAGES ARISING FROM THIS AGREEMENT, THE
                  SITE, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY
                  DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF
                  BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF
                  GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT
                  (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN
                  IF FORESEEABLE AND EVEN IF THE COMPANY HAS BEEN ADVISED OF THE
                  POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE SITES,
                  PRODUCTS OR THIRD PARTY SITES AND PRODUCTS ARE AT YOUR OWN
                  DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR
                  ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF
                  DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE
                  CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM
                  AGGREGATE LIABILITY OF THE COMPANY ARISING OUT OF OR IN ANY
                  WAY RELATED TO THIS AGREEMENT, THE ACCESS TO AND USE OF THE
                  SITE, CONTENT, THE TOMI TOKENS OR THE SERVICES, OR ANY
                  PRODUCTS OR SERVICES PURCHASED ON THE SITE EXCEED THE GREATER
                  OF (A) $100 OR (B) THE AMOUNT YOU HAVE PAID TO THE COMPANY FOR
                  THE SERVICES IN THE LAST SIX MONTHS OUT OF WHICH LIABILITY
                  AROSE. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY
                  TO LIABILITY OF THE COMPANY FOR (A) DEATH OR PERSONAL INJURY
                  CAUSED BY THE COMPANY’S NEGLIGENCE; OR FOR (B) ANY INJURY
                  CAUSED BY THE COMPANY’S FRAUD OR FRAUDULENT MISREPRESENTATION.
                </p>
                <p className="privacypolicymainpara">
                  Some jurisdictions do not allow the exclusion or limitation of
                  incidental or consequential damages, so the above limitation
                  or exclusion may not apply to you. Some jurisdictions also
                  limit disclaimers or limitations of liability for personal
                  injury from consumer products, so this limitation may not
                  apply to personal injury claims.
                </p>
                <h5 className="privacypolicymainhead">16. Indemnification</h5>
                <p className="privacypolicymainpara">
                  To the fullest extent permitted by applicable law, you agree
                  to indemnify, defend and hold harmless the Company, and our
                  respective past, present and future employees, officers,
                  directors, contractors, consultants, equity holders,
                  suppliers, vendors, service providers, parent companies,
                  subsidiaries, affiliates, agents, representatives,
                  predecessors, successors and assigns (individually and
                  collectively, the “Indemnified Parties”), from and against all
                  actual or alleged third party claims, damages, awards,
                  judgments, losses, liabilities, obligations, penalties,
                  interest, fees, expenses (including, without limitation,
                  attorneys’ fees and expenses) and costs (including, without
                  limitation, court costs, costs of settlement and costs of
                  pursuing indemnification and insurance), of every kind and
                  nature whatsoever, whether known or unknown, foreseen or
                  unforeseen, matured or unmatured, or suspected or unsuspected,
                  in law or equity, whether in tort, contract or otherwise
                  (collectively, “Claims”), including, but not limited to,
                  damages to property or personal injury, that are caused by,
                  arise out of or are related to (a) your use or misuse of the
                  Site, Content or Services (including the Tomi Tokens), (b) any
                  Feedback you provide, (c) your violation of this Agreement,
                  (d) your violation of the rights of a third party, including
                  another user or any Third Party Site and (e) your failure to
                  pay any Withholding Taxes or Sales Taxes in connection with
                  your transactions or to provide us with a properly executed
                  tax form described in Section 9. You agree to promptly notify
                  the Company of any third party Claims and cooperate with the
                  Indemnified Parties in defending such Claims. You further
                  agree that the Indemnified Parties shall have control of the
                  defense or settlement of any third party Claims. THIS
                  INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER
                  INDEMNITIES SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND
                  THE COMPANY.
                </p>
                <h5 className="privacypolicymainhead">17. Governing Law</h5>
                <p className="privacypolicymainpara">
                  This Agreement, your use of the Site and the Services, your
                  rights and obligations, and all actions contemplated by,
                  arising out of or related to this Agreement shall be governed
                  by the laws of Switzerland, as if this Agreement is a contract
                  wholly entered into and wholly performed within Switzerland.
                </p>
                <h5 className="privacypolicymainhead">18. Disputes</h5>
                <p className="privacypolicymainpara marginbot">
                  Please read the following agreement to arbitrate (“Arbitration
                  Agreement”) in its entirety. This clause requires you to
                  arbitrate disputes with the Company and limits the manner in
                  which you can seek relief from us.
                </p>
                <p className="privacypolicymainpara marginbot">
                  You agree that any dispute or claim relating in any way to:
                  your access, use, or attempted access or use of the Site; any
                  products sold or distributed through the Site; or any aspect
                  of your relationship with the Company will be resolved by
                  binding arbitration, except that (1) you may assert claims in
                  small claims court if your claims qualify; and (2) you or the
                  Company may seek equitable relief in court for infringement or
                  other misuse of intellectual property rights (such as
                  trademarks, trade dress, domain names, trade secrets,
                  copyright, or patent). You agree that any such arbitration
                  shall be settled solely and exclusively by binding arbitration
                  held in New York, New York, administered by JAMS and conducted
                  in English, rather than in court.
                </p>
                <p className="privacypolicymainpara marginbot">
                  Any such arbitration shall be conducted in accordance with the
                  then-prevailing JAMS Streamlined Arbitration Rules &
                  Procedures, except that any dispute involving claims and
                  counterclaims over $250,000, not inclusive of attorneys’ fees
                  and interest, shall be subject to the then-prevailing JAMS
                  Comprehensive Arbitration Rules and Procedures.
                </p>
                <p className="privacypolicymainpara marginbot">
                  The arbitrator shall have exclusive authority to (1) determine
                  the scope and enforceability of this Arbitration Agreement;
                  and (2) resolve any dispute related to the interpretation,
                  applicability, enforceability or formation of this Arbitration
                  Agreement, including but not limited to any claim that all or
                  part of this Arbitration Agreement is void or voidable; (3)
                  decide the rights and liabilities, if any, of you and The
                  Company; (4) grant motions dispositive of all or part of any
                  claim; (5) award monetary damages and grant any non-monetary
                  remedy or relief available to a party under applicable law,
                  arbitration rules, and these Terms of Use (including the
                  Arbitration Agreement). The arbitrator has the same authority
                  to award relief on an individual basis that a judge in a court
                  of law would have. The arbitrator shall issue a written award
                  and statement of decision describing the essential findings
                  and conclusions on which the award is based, including the
                  calculation of any damages awarded. Such an award is final and
                  binding upon you and us.
                </p>
                <p className="privacypolicymainpara marginbot">
                  You understand that by agreeing to this Arbitration Agreement,
                  you and the Company are each waiving their right to trial by
                  jury and to participate in a class action or class
                  arbitration.
                </p>
                <p className="privacypolicymainpara marginbot">
                  If any part of this Arbitration Agreement is found to be
                  invalid or unenforceable, then such part shall be of no force
                  and effect and shall be severed and the remainder of the
                  Arbitration Agreement shall continue to in full force and
                  effect.
                </p>
                <p className="privacypolicymainpara">
                  This Arbitration Agreement shall survive the termination of
                  your relationship with the Company.
                </p>
                <h5 className="privacypolicymainhead">19. Termination</h5>
                <p className="privacypolicymainpara">
                  We reserve the right, without notice and in our sole
                  discretion, to terminate your license to access or use the
                  Site or Content, at any time and for any reason. You
                  understand and agree that we shall have no liability or
                  obligation to you in such an event.
                </p>
                <h5 className="privacypolicymainhead">20. Severability</h5>
                <p className="privacypolicymainpara">
                  If any term, clause, or provision of this Agreement is held
                  invalid or unenforceable, then that term, clause, or provision
                  shall be severable from this Agreement and will not affect the
                  validity or enforceability of any remaining part of that term,
                  cause, or provision, or any other terms, clause, or provision
                  of this Agreement.
                </p>
                <h5 className="privacypolicymainhead">21. Entire Agreement</h5>
                <p className="privacypolicymainpara">
                  This Agreement comprise the entire agreement between you and
                  the Company relating to your access to and use of the Site,
                  the Services and Content, and supersede any and all prior
                  discussions agreements, and understandings of any kind
                  (including without limitation prior versions of this User
                  Agreement). Except as otherwise provided herein, this
                  Agreement is intended solely for the benefit of the parties
                  and are not intended to confer third party beneficiary rights
                  upon any other person or entity.
                </p>
                <h5 className="privacypolicymainhead">22. Survival</h5>
                <p className="privacypolicymainpara">
                  You agree and understand that all provisions of this Agreement
                  shall survive the termination or expiration of this Agreement.
                </p>
                <h5 className="privacypolicymainhead">
                  23. Contact Information
                </h5>
                <p className="privacypolicymainpara">
                  If you have any questions, would like to provide feedback, or
                  would like more information about TOMI, please feel free to
                  email us at service@tomi.com.
                </p>
                <h5 className="privacypolicymainhead">END OF AGREEMENT</h5>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Useraagreement;
