import axios from 'axios'
import { API_URL } from '../../utils/ApiUrl';


export const GetUsers = (account, token) => async (dispatch) => {
  // dispatch({
  //   type: "loader",
  //   payload: true,
  // });

  if (account && token) {
    // console.log("accccc in reduc",account)
    await axios.post(API_URL + "users/getUser", { walletAddress: account }, { headers: { "Authorization": `Bearer ${token}` } })
      .then(async (res) => {
        //   if (res?.data?.code === 401) {
        //     const res1 = await userSign();
        //     if (res1) {
        //         console.log("herererer")
        //         axios.post(API_URL + "users/login", { walletAddress: account, sign: res1 })
        //             .then((response) => {
        //                 console.log("resss", response.data.token)
        //                 const token = response.data.token
        //                 localStorage.setItem('mytoken', token)

        //             }).catch((err) => {
        //                 //   setOpen(false)
        //                 //   toast.error(err.response?.data.msg, {
        //                 //     position: "top-center",
        //                 //     autoClose: 2000,
        //                 //   });
        //             })
        //     }
        // }
        if (res.data.user != null) {
          // loader=false
          // console.log("iff  s",res.data.user)
          dispatch({
            type: "GETUSER",
            payload: res.data.user,
          });
          // dispatch({
          //   type: "loader",
          //   payload: false,
          // });
        } else {
          // console.log('else')
          // loader=false
          dispatch({
            type: "GETUSER",
            payload: '',
          });
          // dispatch({
          //   type: "loader",
          //   payload: false,
          // });
        }
      })
      .catch((err) => {
        // loader=false
        console.log("errrrrrrr,", err)
        return false;
      })
  }

};

// export const GetUserNft = (account) => async (dispatch) => {
//   if (account) {
//     console.log("accccc in reduc", account)
//     const options = {
//       method: 'GET',
//       url: `https://deep-index.moralis.io/api/v2/${account}/nft?chain=goerli&format=decimal&normalizeMetadata=false&token_addresses=0x40118DBDC838e2f91A9987f898D55519510f887f`,
//       headers: { accept: 'application/json', 'X-API-Key': 'XQSv7SUI3RedCHwf2AB8c1cwQa9JE7G3nszRFc6rZroheiQK2dWOBpZCujt1qSil' }
//     };

//     await axios
//       .request(options)
//       .then(function (response) {
//         console.log(response.data.result);
//         if (response.data.result != null) {
//           // loader=false
//           // console.log("iff  s",res.data.user)
//           dispatch({
//             type: "GETUSERNFT",
//             payload: response.data.result,
//           });
//         } else {
//           dispatch({
//             type: "GETUSERNFT",
//             payload: '',
//           });
//         }
//       })
//       .catch(function (error) {
//         console.error(error);
//       });
//   }

// };

