import React, { useEffect, useState } from "react";
import "./navbarnew.scss";
import { useWeb3React } from "@web3-react/core";
import { NavLink, Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { toast } from "react-toastify";
// import DumImage from "../../../Assets/user.png";
// import * as mdb from 'mdb-ui-kit'; // lib
// import { Input } from 'mdb-ui-kit'; // module
import { CopyToClipboard } from 'react-copy-to-clipboard';

import axios from "axios";
import { useHistory } from "react-router-dom";

import useWeb3 from "../../hooks/useWeb3";
import { connectorsByName } from "../../utils/web3React";
import Offcanvas from 'react-bootstrap/Offcanvas';

import {
  Paper,
  Box,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Accordion } from "react-bootstrap";
// });
const NavbarNew = ({ setIndexxxx }) => {
  const AllowedUsers = localStorage.getItem("UserAllowed");
  // console.log("allowed user", AllowedUsers);
  const [stickyClass, setStickyClass] = useState(false);
  const Token = localStorage.getItem("AccessToken");
  const history = useHistory();
  const [toggle, setToggle] = useState();
  const [idelem, setidelem] = useState("629880b29f10584b3b3b341f");
  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);
  const openbuttonss = () => {
    setToggle(true);
  };


  // let reg = RegExp(/^[A-Za-z]{5}$/i);
  // console.log("=========== we get here is",   reg.test("hello"))


  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // console.log("windowscroll", windowHeight);
      windowHeight > 20 ? setStickyClass(true) : setStickyClass(false);
    }
  };

  const Signout = () => {
    if (Token) {
      localStorage.removeItem("AccessToken");
      history.push("/home");
      window.location.reload();
      toast.error("You have Logout", {
        position: "top-center",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    let path = window.location.pathname;
    setindex(path)
  }, [])

  const MoveActive = (index, route) => {
    setindex(index);
    history.push(route)

  }
  // const Closemodal = () => {
  //   window.$("#exampleModalwhite44").modal("hide");
  // }

  // useEffect(() => {
  //   if (AllowedUsers == "true") {
  //     window.$("#exampleModalwhite22").modal("hide");
  //   } else {
  //     window.$("#exampleModalwhite22").modal("show");
  //   }
  // }, [AllowedUsers]);

  // const validationSchema = Yup.object().shape({
  //   password: Yup.string()
  //     .required('Password is required'),
  // });

  // const [type, setType] = useState("password")
  // const [passwordss, setpasswordss] = useState("satoshi")
  // const {
  //   register,
  //   control,
  //   handleSubmit,
  //   formState: { errors }
  // } = useForm({
  //   resolver: yupResolver(validationSchema)
  // });
  // const onSubmit = data => {
  //   console.log("success", data)
  //   if (passwordss == data?.password) {
  //     toast.success('Allowed User', {
  //       position: "top-center",
  //       autoClose: 3000,
  //     });
  //     localStorage.setItem('UserAllowed', true)
  //     window.$("#exampleModalwhite22").modal("hide");
  //   } else {
  //     toast.error('Wrong Password User Not Allowed', {
  //       position: "top-center",
  //       autoClose: 3000,
  //     });
  //   }
  // };
  const [index, setindex] = useState(0);
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const { login, logout } = useAuth();
  const [opens, setOpens] = useState(false);
  // console.log("wallet connect", account);
  const { activate } = useWeb3React();

  // const connectCoinBase = async () => {
  //   try {
  //     localStorage.setItem('connectorId', "walletlink")
  //     // localStorage.setItem("connectorId", "walletconnect");
  //     if (account) {
  //       logout()
  //     } else {
  //       login("coinbaseWallet");
  //     }
  //   } catch (e) {
  //     console.log(e)
  //   }
  // }
  console.log("index value", index)



  const [showmobilesidebar, setShowMobileSidebar] = useState(false);
  const handleCloseMobileSidebar = () => setShowMobileSidebar(false);
  const handleShowMobileSidebar = () => setShowMobileSidebar(true);

  const [isShown, setIsShown] = useState(false);

  const Closemodal = () => {
    window.$('#exampleModalwhite44445').modal('hide');
    // history.push('/home')
  };
  const openmodals = () => {
    window.$('#exampleModalwhite44445').modal('show');

  }
  const [copied, setCopied] = useState(false);
  const textCopiedFun = () => {
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 1000)
  }

  return (
    <>
      <section className="main-navbar-mobilenew fgfgfgfgfgf fgfggfhfghfgh" >
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-xl-11 col-lg-11 m-auto">
              <nav className="navbar  navbar-expand-xl ">
                <a href="#" className="navbar-brand sdvgdvsdhvd">
                  <Link to="/"
                  >
                    <img
                      src="/logo.svg"
                      alt="img"
                      className="img-fluid gcsgcdgsdvcgc"
                    />
                    <img
                      src="/newmmm/tomipay-logo.svg"
                      alt="img"
                      className="img-fluid tomipay-logo d-none"
                    />
                  </Link>
                </a>
                <div className="ahsbahsba">
                  <Link to="/">
                    <img src="/logo-new.svg" alt="img" className="img-fluid vsvtsvtsc" />
                    <img src="/newmmm/tomipay-logo.svg" alt="img" className="img-fluid tomipay-logo d-none" />

                  </Link>
                </div>
              </nav>
            </div>
          </div>
        </div>

        
      </section>

   

   


    

     
    </>
  );
};

export default NavbarNew;
