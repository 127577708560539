import React, { useEffect, useState } from 'react'
import styled from "@emotion/styled/macro";
import "./editprofile.scss"
import { useWeb3React } from "@web3-react/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {useDispatch} from 'react-redux'
import Loader from "../../hooks/loader"
import {GetUsers} from '../../redux/action'
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../../utils/ApiUrl";
import Signature from "../../hooks/dataSenders/userSign";
import { useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

const Copied = styled("span")`
  font-size: 16px;
  margin-left: 10px;
  position: absolute;
            right: 14px;
            top: 42px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #FF0083;
            text-decoration: none;
            border: none;
            background-color: transparent;
`;
const HOVER_TIMEOUT = 1000;



const Editprofile = () => {
    const dispatch=useDispatch();
    const { userSign } = Signature();
    const token = JSON.parse(localStorage?.getItem('mytoken'))
    const history=useHistory()
  const users = useSelector((state) => state.UserReducer.GetUserAll);
  console.log("eserr",users)
    const [selectedImg1, setSelectedImg1] = useState();
    const [mainLoader, setMainLoader] = useState(false);
    const [myFiles, setMyFiles] = useState();
    const { account } = useWeb3React();
    const [copy, setCopy] = useState(false);
    const [inputs, setInputs] = useState({
        userEmail: "",
        name: "",
        discordLink: "",
        walletAddress: "",
    })

    console.log("inputsssss", inputs) 

    const userLogin = async () => {
        const res1 = await userSign();
        if (res1 && account) {
            console.log("herererer")
            axios.post(API_URL + "users/login", { object: { address: account,name : "Tomi Dao"},sign: res1 })
                .then((response) => {
                    console.log("resss", response.data.token)
                    const token = response.data.token
                    localStorage.setItem('mytoken',JSON.stringify({token, account}))
                    dispatch(GetUsers(account,token));
    
                }).catch((err) => {
                    //   setOpen(false)
                    //   toast.error(err.response?.data.msg, {
                    //     position: "top-center",
                    //     autoClose: 2000,
                    //   });
                })
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    const editUser= async(event)=>{
            
            setMainLoader(true)
            event.preventDefault();
            const data = new FormData();
            if(inputs.name){
                data.append("name", inputs?.name);
            }else{
                data.append("name", users?.name);  
            }
            if(inputs.userEmail){
                data.append("userEmail", inputs.userEmail);
            }else{
                data.append("userEmail", users?.userEmail );
            }
            if(inputs.discordLink){
                data.append("discordLink", inputs.discordLink); 
            }else{
                data.append("discordLink",users?.discordLink);
            }
          
            // data.append("userEmail", inputs.userEmail ? inputs.userEmail : users?.userEmail );
            // data.append("discordLink", inputs.discordLink ? inputs.discordLink : users?.discordLink);
            if (myFiles) {
              data.append("picture", myFiles);
            }
            data.append(
              "walletAddress", account
              );
            //   data.append("name", "any");
              data.append("role", "user");
              
              console.log("yeeeeeee")
              axios
                .post(API_URL + "users/editUser", data, { headers: { "Authorization": `Bearer ${token?.token}` } })
                .then((response) => {
                  setMainLoader(false);
                  toast.success('Profile Edited', {
                    position: "top-center",
                    autoClose: 2000,
                  });

                  history.push('/home')


                  dispatch(GetUsers(account,token?.token));
                //   history.push("/profile");
                  // setInputs({
                  //     name: '',
                  //     description: '',
                  //     routeLink: '',
                  //     routePage: '',
                  //     category: '',
                  //     type: '',
                  //     image: ''
                  // })
                  // setSelectedImg('');
                })
                .catch((err) => {
                    if (err.toString().slice(39, 43) === '401') {
                        userLogin()
                    }
                  
                  setMainLoader(false);
                  toast.error(err.response.data.msg, {
                    position: "top-center",
                    autoClose: 2000,
                  });
               
                });
            // } else {
            //   setMainLoader(false);
            //   toast.error("name cannot be empty", {
            //     position: "top-center",
            //     autoClose: 2000,
            //   });
            // }
    }

    const deleteProfile = () => {
        setMainLoader(true)
        axios.post(API_URL + `users/deleteCoverPhoto`,{}, { headers: { "Authorization": `Bearer ${token?.token}` } })
            .then((response) => {
              setMainLoader(false);
              toast.success('Profile Deleted', {
                position: "top-center",
                autoClose: 2000,
              });
              dispatch(GetUsers(account,token?.token));
            //   history.push("/profile");
            }).catch((error)=>{
               setMainLoader(false)
            })
            setMyFiles();
        setSelectedImg1(null);
      };
    

    const handleFileSelect = (evt) => {
        if (evt.target.files) {
            const filesarray = Array.from(evt.target.files).map((file) => URL.createObjectURL(file));
            setSelectedImg1(filesarray[0]);
        }
        var files = evt.target.files;
        var file = files[0];
        setMyFiles(file)
    }
    return (
        <>
          {mainLoader && <Loader />}
            <section className='Editprofile ptb'>
                <div className='container-fluid p-0'>
                    <div className='row'>
                        <div className='col-sm-11 m-auto'>
                            <div className='row'>
                                <div className='col-sm-12 p-0'>
                                    <div className='mainheadings'>
                                        <h4 className='head'>Edit profile</h4>
                                        <p className='para'>You can set preferred display name and manage other personal settings All Fields Are Optional</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row direction'>
                                <div className='col-lg-7 col-md-12 col-sm-12 p-0'>
                                    <div className='maininput'>
                                        <p>Wallet Address (Optional)</p>
                                        <input type="text" value={account ? account : 'Connect Wallet'} name='walletAddress' disabled className='walletinput' />
                                        {/* <button className='copy'>Copy</button> */}
                                        {copy ? (
                                            <Copied>Copied</Copied>
                                        ) : (
                                            // {account}
                                            <CopyToClipboard
                                                text={account}
                                                onCopy={() => {
                                                    setCopy(true);
                                                    setTimeout(
                                                        () => setCopy(false),
                                                        HOVER_TIMEOUT
                                                    );
                                                }}
                                            >
                                                <button className='copy'>Copy</button>
                                            </CopyToClipboard>
                                        )}
                                        {/* <buttton href='' className='copy'/>Copy */}
                                    </div>
                                    <div className='maininput'>
                                        <p>Name (Optional)</p>
                                    <input type="text" name='name' value={inputs?.name ?inputs?.name : users.name } onChange={handleChange1} placeholder='Enter your display name' className='innerinput' />
                                    </div>
                                    <div className='maininput'>
                                        <p>Email (Optional)</p>
                                        <input type="text" name='userEmail' value={inputs?.userEmail ? inputs?.userEmail : users.userEmail} onChange={handleChange1} placeholder='Enter your email' className='innerinput' />
                                    </div>
                                    <div className='maininput'>
                                        <p>Discord (Optional)
                                            <img src='\Discord.svg' alt='img' className='discordimg' />
                                        </p>
                                        <input type="text" name='discordLink' value={inputs?.discordLink ? inputs?.discordLink : users.discordLink} onChange={handleChange1} placeholder='Enter your Discord' className='innerinput' />
                                    </div>
                                    <button className='endbtn' onClick={editUser}>Update Profile</button>
                                </div>
                                <div className='col-lg-5 col-md-12 col-sm-12 padd'>
                                    <div className='rightmain'>
                                        <h6>Profile Photo (Optional)</h6>
                                        <div className='maininner'>
                                            <img src={selectedImg1 ? selectedImg1 : users.picture?users.picture: '\avatar-03.png'} className='avatarimg' alt='img' />
                                            <div className='innered'>
                                                <p>We recommend an image of at least 300x300.</p>
                                                <div className='inning'>
                                                    <label className='upload-btn' htmlFor='upload' >Upload</label>
                                                    <input type="file" className='d-none' onChange={handleFileSelect} accept="image/*" id='upload' />
                                                    <div className='outing' onClick={deleteProfile}>
                                                        <img src='\trash.svg' alt='img' className='trashimg' />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Editprofile