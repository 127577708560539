import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getAllProposal } from "../../utils/contractHelpers";
const UserVote = () => {
    const web3 = useWeb3();
    const contractAddress = environment.CreateProposal;
    const contract = getAllProposal(contractAddress, web3);
    const voted = useCallback(
        async (id,support, account,NFtID) => {
            // let a=[]
            // a.push(NFtID)
            console.log("sdfsfdsfdsf",id, support, account,NFtID,contractAddress,contract)
            try {
                let gasPrice = await web3.eth.getGasPrice();
                // let gasPrice2 = parseInt(gasPrice) + 50000000000
                const gas = await contract.methods
                    .castVote(id, support,NFtID).estimateGas({from: account})
                    const details = await contract.methods
                    .castVote(id, support,NFtID)
                    .send({
                        from: account,
                        gas,
                        gasPrice:gasPrice 
                    })
                return details;
            } catch (error) {
                console.log("catchhhh",error)
                throw (error)
            }
        },     
        [contract]
    );
    return { voted: voted };
};
export default UserVote;