import React from 'react'
import Banner from './Banner/Banner'
import Navbartop from './headertop/Navbartop'
import Navbar from './Navbar/Navbar'
import NavbarNew from './NavbarNew/NavbarNew'

function Landing() {
  return (
    <>
    <NavbarNew />
    <Navbar/>
    <Banner/>
    </>
  )
}

export default Landing