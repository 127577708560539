import React, { Component } from 'react';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import "./submitporposal.scss";


class TextEditor extends Component {
  state = {
    editorState: null,
  }

  onEditorStateChange = (editorState) => {
    // console.log("🚀 ~ file: TextEditor.js ~ line 14 ~ TextEditor ~ editorState", editorState)
    this.setState({
      editorState,
    });
    this.props.onEditorChange(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  };

  render() {
    const { editorState } = this.state;
    
    return (
      <div>
        <Editor

          wrapperClassName="demo-wrapper article_TextEditor"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
        />
        <textarea
          disabled hidden
          value={editorState && draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        />
      </div>
    );
  }
}

export default React.memo(TextEditor);