let initState = {
  GetUserAll: [],
  GetUserNFT: [],
  GETTreasuryNFT: [],
};

export const UserReducer = (state = initState, action) => {
  const { type, payload } = action; //object destructring
  switch (type) {
    case "GETUSER":
      return {
        ...state,
        GetUserAll: payload,
      };
    case "GETUSERNFT": 
      return {
        ...state,
        GetUserNFT: payload,
      };
      case "GETTreasuryNFT": 
      return {
        ...state,
        GETTreasuryNFT: payload,
      };
    case "USER_REWARD":

      return {
        ...state,
        reward: payload,
      };

    default:
      return state;
  }
};